import React from "react";
import "./index.css";
const Loader = () => {
    return (
        <div className="loader">
            <div className="loader__container">
                <div className="loader__container__spinner">
                </div>
            </div>
        </div>
    );
}

export default Loader;
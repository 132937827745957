import React from "react";
import { Row } from "reactstrap";
import "./Footer.css";
import UserProfileImagePlaceholder from "../../../Images/user-profile-pic-placeholder.png";

const WorkoutDetailFooter = (props) => {
  return (
    <div className="workout-detail-footer-container">
      <div className="workout-detail-footer-content">
        <Row className="workout-detail-footer-row-content">
          <img
            src={
              props.workoutData.userProfilePic
                ? props.workoutData.userProfilePic
                : UserProfileImagePlaceholder
            }
            alt="User Profile"
            className="workout-detail-footer-profile-image"
          />
        </Row>
        <Row className="workout-detail-footer-row-content">
          <p
            className="workout-detail-footer-text-content"
            style={{ marginBottom: 0, marginTop: "1rem" }}
          >
            See more workouts from
          </p>
          <p className="workout-detail-footer-text-content">{`${props.workoutData.userName}`}</p>
        </Row>
        <Row className="workout-detail-footer-get-train-button-row">
          <a
            className="workout-detail-footer-get-train-button"
            href="https://apps.apple.com/ca/app/train-fitness-workout-tracker/id1548577496"
          >
            <div className="workout-detail-footer-get-train-text">
              Get Train Fitness
            </div>
          </a>
        </Row>
      </div>
    </div>
  );
};

export default WorkoutDetailFooter;

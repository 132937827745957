import React from "react";
import { Row, Col } from "reactstrap";
import "./WorkoutPictures.css";

const WorkoutDetailOverview = (props) => {
  return (props.workoutData?.workoutImageURLs || []).length > 0 ? (
    <div className="workout-pictures">
      <Row>
        <p className="workout-pictures-header">Pictures</p>
      </Row>
      <div className="workout-pictures-row">
        {props.workoutData.workoutImageURLs.map((url) => (
          <img
            key={url}
            src={url}
            alt="Workout"
            className="workout-pictures-image"
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default WorkoutDetailOverview;

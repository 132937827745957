import React, { useEffect, useState } from "react";
import { isIOS, isMobile, isAndroid, isDesktop } from "react-device-detect";

import "./index.css";
import useScreenSize from "../../utils/screenHook";
import Loader from "../Loader";
import { Router } from "react-router-dom/cjs/react-router-dom";

const IOSExclusive = () => {
  // const [ready, setReady] = useState(false);
  const isProfilePage = window.location.pathname.includes("/profile");
  // useEffect(() => {
  //     if (isDesktop) {
  //       // Open the profile page in the browser
  //       window.open(`${window.location.protocol}//${window.location.host}${window.location.pathname}/web`)
  //     } else if (isIOS) {
  //       // Open the profile page in the app
  //       // window.location.href = `https://trainfitness.ai/${window.location.pathname}/web`;

  //       window.open(`${window.location.protocol}//${window.location.host}${window.location.pathname}/web`);
  //     }
  //     setReady(true);
  // }, []);

  const onClick = () => {
    window.open(`${window.location.protocol}//${window.location.host}${window.location.pathname}/web`);
  };

  //   const match = useRouteMatch();
  const screenSize = useScreenSize();
  const isBigScreen = screenSize.width > 768;
  // console.log(isIOS, isMobile, isAndroid, isDesktop);
  // if (!ready) {
  //   return <Loader />;
  // }
  return (
    <div className="ios-exclusive responsive-container">
      <div className="content">
        <div className="content-box">
          <div className="logo-container">
            <img
              src="/images/train-logo-profile.png"
              alt="logo"
              height="30px"
            />
          </div>
          <div>
            <h2 className="title">
              {isIOS || isDesktop
                ? "Download Train Fitness to view this workout."
                : "Train Fitness is currently available exclusively for iOS devices."}
            </h2>
            <div className="caption">
              {isIOS || isDesktop
                ? "💪 Automatic exercise detection & rep counting, enabled using AI and Apple Watch."
                : "💪 Stay tuned, and ️thanks for your interest in joining our community!"}
            </div>
          </div>
          <div className="actions">
            {isIOS ||
              (isDesktop && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    window.location.href = `https://apps.apple.com/ca/app/train-fitness-workout-tracker/id1548577496`;
                  }}
                >
                  Get Train Fitness
                </button>
              ))}
            {isAndroid && isMobile && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  window.location.href = `https://trainfitness.typeform.com/android`;
                }}
              >
                Join Waitlist
              </button>
            )}
            <button className="btn btn-secondary" onClick={onClick}>
              View the {isProfilePage ? "Profile" : "Workout"} in Browser
            </button>
          </div>

          <div>
            <img
              className="appstore-img"
              src="/images/appstore-download.png"
              alt="Download from Appstore"
              height="50px"
            />
          </div>
        </div>
      </div>
      <div className="media-box">
        <div className="media-box-container">
          {isIOS && (
            <img
              className="appstore-img"
              src="/images/appstore-download.png"
              alt="Download from Appstore"
              height="50px"
            />
          )}
          <img
            className="bg-img"
            src={
              isBigScreen
                ? "/images/web-media-ios.png"
                : "/images/mobile-footer-ios.png"
            }
            alt="Media"
            h
          />
        </div>
      </div>
    </div>
  );
};


export default IOSExclusive;

import "./style.scss";
import { Col, Row, Container } from "reactstrap";
import logoTrain from "../../Images/train_logo.png";
import logoApple from "../../Images/apple_logo_32px.svg";
import { Link } from "react-router-dom";
import { isIOS, isMobile } from "react-device-detect";
import { useSetRecoilState } from "recoil";
import { joinModalState } from "../../recoil/atoms";

function Header() {
  const setJoinModalState = useSetRecoilState(joinModalState);
  return (
    <Container className="">
      <header className="site-header">
        <Row className="justify-content-center align-self-center align-items-center">
          <Col className={"site-header-left "}>
            <Link to="/" className="train-logo">
              <img src={logoTrain} alt="" />
            </Link>
          </Col>
          <Col className={"site-header-right"}>
            <a
              onClick={() => {
                // if (isMobile && isIOS) {
                //   window.location = "https://app.trainfitness.ai/HcEiINpgJvb";
                // } else {
                //   setJoinModalState(true);
                // }
                // window.open("https://apps.apple.com/app/id1548577496", "_blank");
                // window.open("https://apps.apple.com/redeem?ctx=offercodes&id=1548577496&code=BARBELLWORKOUTS", "_blank");
                window.open("https://app.trainfitness.ai/e/TryTrain1Month", "_blank");


              }}
              className="cta-btn"
            >
              <img src={logoApple} alt="" />
              Join Train for Free
            </a>
          </Col>
        </Row>
      </header>
    </Container>
  );
}

export default Header;

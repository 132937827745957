import React from "react";
import { Container, Row, Col } from "reactstrap";
import Left from "./Left";
import Right from "./Right";
import "./style.css";

const WebHome = () => {
  return (
    <Container fluid>
      <Row>
        <Col md="6">
          <Left />
        </Col>
        <Col md="6" style={{ height: "100vh" }}>
          <Right />
        </Col>
      </Row>
    </Container>
  );
};

export default WebHome;

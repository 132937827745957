import React from "react";
import { Row } from "reactstrap";
import "./WorkoutLog.css";
import superSetImage from "../../../Images/superset.png";

const WorkoutLogLine = ({ key, heading, sets, isLast }) => (
  <div className="workout-log-line" key={key}>
    <p className="workout-log-heading">{heading}</p>
    <div className="workout-log-sets">
      {sets.map((set, index) => (
        <p key={index} className="workout-log-set-row">
          <span className="workout-log-set">{`Set ${index + 1}`}</span>
          <span className="workout-log-set-value">
            {`${set.reps} reps x ${
              set.weight > 0 ? `${set.weight} lbs` : "BW"
            }`}
          </span>
        </p>
      ))}
    </div>
    <hr />
  </div>
);

const getWorkoutLogItem = (exerciseItem) => {
  if (exerciseItem.length > 0) {
  } else {
    return (
      <WorkoutLogLine
        heading={exerciseItem.exerciseName}
        sets={exerciseItem.sets}
        isLast
      />
    );
  }
};

const WorkoutLog = (props) => {
  return props.workoutData.sets.length > 0 ||
    props.workoutData.superSets.length > 0 ? (
    <div className="workout-log">
      <Row>
        <p className="workout-log-header">Workout Log</p>
      </Row>
      {props.workoutData.sets.length > 0 ? (
        <Row>
          {props.workoutData.sets.map((exerciseItem, index) => (
            <div key={index} className="workout-log-custom-div">
              {getWorkoutLogItem(exerciseItem)}
            </div>
          ))}
        </Row>
      ) : null}
      {props.workoutData.superSets.length > 0 ? (
        <div>
          <div className="workout-log-superset-header">
            <img
              src={superSetImage}
              alt="Superset"
              className="workout-log-superset-image"
            />
            Superset
          </div>
          <Row>
            {props.workoutData.superSets.map((superSet, index) =>
              superSet.length > 0 ? (
                <div className="workout-log-custom-div">
                  {superSet.map((exerciseItem, index) => (
                    <WorkoutLogLine
                      key={index}
                      heading={exerciseItem.exerciseName}
                      sets={exerciseItem.sets}
                    />
                  ))}
                </div>
              ) : null
            )}
          </Row>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default WorkoutLog;

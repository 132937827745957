import React from "react";
import { Container, Row, Col } from "reactstrap";

const Left = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const workoutId = urlParams.get("id");

  return (
    <Container style={{ height: "100vh" }}>
      <Row style={{ height: "25%" }}>
        <Col className="top-section">
          <img
            className="train-logo"
            src="https://storage.googleapis.com/public-train/branch-page-assets/android/Train%20LOGO%20%403x.png"
          />
        </Col>
      </Row>
      <Row style={{ height: "25%" }}>
        <Col>
          <p className="text1">Download Train Fitness</p>
          <p className="text2">to view this workout.</p>
          <p className="text3">
            💪 Automatic exercise detection & rep counting,
          </p>
          <p className="text4">enabled using AI and Apple Watch.</p>
        </Col>
      </Row>
      <Row style={{ height: "25%", paddingTop: "2%" }}>
        <Col>
          <Row className="get-train-button-row">
            <a
              className="get-train-button"
              href="https://apps.apple.com/ca/app/train-fitness-workout-tracker/id1548577496"
            >
              <div className="get-train-text">Get Train Fitness</div>
            </a>
          </Row>
          <Row className="view-workout-button-row">
            <a
              className="view-workout-button"
              href={`http://localhost:3000/workout-detail/?id=${workoutId}`}
            >
              <div className="view-workout-text">
                View the Workout in Browser
              </div>
            </a>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: "25%" }}>
        <Col>
          <a href="{{ app.ios_url }}" className="app-store-download-image">
            <img
              className="app-store-badge"
              src="https://storage.googleapis.com/public-train/branch-page-assets/desktop/Apple%20Store%20Badge%403x.png"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Left;

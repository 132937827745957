import React from "react";
import { Row } from "reactstrap";
import "./PrivateWorkout.css";
import NoAccessImage from "../../../Images/no-access.png";

const PrivateWorkout = (props) => {
  return (
    <div className="private-workout-container">
      <div className="private-workout-content">
        <Row className="private-workout-row-content">
          <img
            src={NoAccessImage}
            alt="No Access"
            className="private-workout-image"
          />
        </Row>
        <Row className="private-workout-row-content">
          <p
            className="private-workout-text-content"
            style={{ marginBottom: 0, marginTop: "1rem" }}
          >
            This is a private workout
          </p>
        </Row>
      </div>
    </div>
  );
};

export default PrivateWorkout;

import React from "react";
import "./index.css";

const ErrorPage = () => {
    // Cross and something went wrong below it 

    return (
        <div className="error-page">
            <div className="error-cross">
                <img src="/images/cross.svg" alt="error-cross" />
            </div>
            <div>
            <h1>Something went wrong.</h1>
            </div>
        </div>
    );
}

export default ErrorPage;
import { Container } from "reactstrap";
import "./style.scss";

function PrivacyPolicy() {
  return (
    <Container className="mt-5 mb-5 privacy_page">
      <h1>PRIVACY NOTICE</h1>
      <div classNameName="mt-3">
        <p>
          <strong>
            Last updated February 24<sup>th</sup>, 2024
          </strong>
        </p>

        <div class="c27 doc-content">
          
          <p class="c3">
            <span class="c0">
              This privacy notice for Train Fitness Inc. (&quot;
            </span>
            <span class="c0 c11">we</span>
            <span class="c0">,&quot; &quot;</span>
            <span class="c0 c11">us</span>
            <span class="c0">,&quot; or &quot;</span>
            <span class="c0 c11">our</span>
            <span class="c0">
              &quot;), describes how and why we might collect, store, use,
              and/or share (&quot;
            </span>
            <span class="c0 c11">process</span>
            <span class="c0">
              &quot;) your information when you use our services (&quot;
            </span>
            <span class="c0 c11">Services</span>
            <span class="c5 c0">&quot;), such as when you:</span>
          </p>
          <ul class="c16 lst-kix_3x7jabvvndmc-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Download and use our mobile application (Train Fitness Workout
                Tracker), or any other application of ours that links to this
                privacy notice
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Engage with us in other related ways, including any sales,
                marketing, or events
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0 c11">Questions or concerns? </span>
            <span class="c5 c0">
              Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. If you still have any
              questions or concerns, please contact us at hello@trainfitness.ai.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">SUMMARY OF KEY POINTS</span>
          </h2>
          <p class="c3">
            <span class="c0 c11">What personal information do we process?</span>
            <span class="c5 c0">
              &nbsp;When you visit, use, or navigate our Services, we may
              process personal information depending on how you interact with us
              and the Services, the choices you make, and the products and
              features you use.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">
              Do we process any sensitive personal information?
            </span>
            <span class="c5 c0">
              &nbsp;We may process sensitive personal information when necessary
              with your consent or as otherwise permitted by applicable law.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">
              Do we receive any information from third parties?
            </span>
            <span class="c5 c0">
              &nbsp;We do not receive any information from third parties.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">How do we process your information?</span>
            <span class="c5 c0">
              &nbsp;We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">
              In what situations and with which types of parties do we share
              personal information?
            </span>
            <span class="c5 c0">
              &nbsp;We may share information in specific situations and with
              specific categories of third parties.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">How do we keep your information safe?</span>
            <span class="c5 c0">
              &nbsp;We have organizational and technical processes and
              procedures in place to protect your personal information. However,
              no electronic transmission over the internet or information
              storage technology can be guaranteed to be 100% secure, so we
              cannot promise or guarantee that hackers, cybercriminals, or other
              unauthorized third parties will not be able to defeat our security
              and improperly collect, access, steal, or modify your information.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">What are your rights?</span>
            <span class="c5 c0">
              &nbsp;Depending on where you are located geographically, the
              applicable privacy law may mean you have certain rights regarding
              your personal information.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">How do you exercise your rights?</span>
            <span class="c0">
              &nbsp;The easiest way to exercise your rights is by visiting
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://trainfitness.ai"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://trainfitness.ai"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">
              , or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">TABLE OF CONTENTS</span>
          </h2>
          <p class="c3">
            <span class="c1">1. WHAT INFORMATION DO WE COLLECT?</span>
          </p>
          <p class="c3">
            <span class="c1">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
          </p>
          <p class="c3">
            <span class="c1">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </span>
          </p>
          <p class="c3">
            <span class="c1">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </p>
          <p class="c3">
            <span class="c1">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
          </p>
          <p class="c3">
            <span class="c1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
          </p>
          <p class="c3">
            <span class="c1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
          </p>
          <p class="c3">
            <span class="c1">8. DO WE COLLECT INFORMATION FROM MINORS?</span>
          </p>
          <p class="c3">
            <span class="c1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
          </p>
          <p class="c3">
            <span class="c1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
          </p>
          <p class="c3">
            <span class="c1">
              11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p class="c3">
            <span class="c1">
              12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p class="c3">
            <span class="c1">13. DO WE MAKE UPDATES TO THIS NOTICE?</span>
          </p>
          <p class="c3">
            <span class="c1">
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </p>
          <p class="c3">
            <span class="c1">
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">1. WHAT INFORMATION DO WE COLLECT?</span>
          </h2>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <h3 class="c19" >
            <span class="c21 c11 c26">
              Personal information you disclose to us
            </span>
          </h3>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              We collect personal information that you provide to us.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">Personal Information Provided by You.</span>
            <span class="c5 c0">
              &nbsp;The personal information that we collect depends on the
              context of your interactions with us and the Services, the choices
              you make, and the products and features you use. The personal
              information we collect may include the following:
            </span>
          </p>
          <ul class="c16 lst-kix_5b09ela62ii0-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">names</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">email addresses</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">usernames</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">passwords</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">contact preferences</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">contact or authentication data</span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0 c11">Sensitive Information.</span>
            <span class="c5 c0">
              &nbsp;When necessary, with your consent or as otherwise permitted
              by applicable law, we process the following categories of
              sensitive information:
            </span>
          </p>
          <ul class="c16 lst-kix_z7vvm8mmr3qz-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">health data</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">biometric data</span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0 c11">Payment Data.</span>
            <span class="c0">
              &nbsp;We may collect data necessary to process your payment if you
              make purchases, such as your payment instrument number, and the
              security code associated with your payment instrument. All payment
              data is stored by Apple. You may find their privacy notice link(s)
              here:
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://developer.apple.com/app-store/app-privacy-details/&amp;sa=D&amp;source=editors&amp;ust=1708839853033110&amp;usg=AOvVaw0Jf5X9VwjYPv7Nf6x_hqff"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://developer.apple.com/app-store/app-privacy-details/&amp;sa=D&amp;source=editors&amp;ust=1708839853033482&amp;usg=AOvVaw1IN0uAOM2oTRasI1IPwi_8"
              >
                https://developer.apple.com/app-store/app-privacy-details/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c0 c11">Social Media Login Data. </span>
            <span class="c0">
              We may provide you with the option to register with us using your
              existing social media account details, like your Facebook,
              Twitter, or other social media account. If you choose to register
              in this way, we will collect the information described in the
              section called &quot;
            </span>
            <span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
            <span class="c5 c0">&quot; below.</span>
          </p>
          <p class="c3">
            <span class="c0 c11">Application Data.</span>
            <span class="c5 c0">
              &nbsp;If you use our application(s), we also may collect the
              following information if you choose to provide us with access or
              permission:
            </span>
          </p>
          <ul class="c16 lst-kix_nvh4tsm6dhz2-0 start">
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Geolocation Information.</span>
              <span class="c5 c0">
                &nbsp;We may request access or permission to track
                location-based information from your mobile device, either
                continuously or while you are using our mobile application(s),
                to provide certain location-based services. If you wish to
                change our access or permissions, you may do so in your
                device&#39;s settings.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Mobile Device Access.</span>
              <span class="c5 c0">
                &nbsp;We may request access or permission to certain features
                from your mobile device, including your mobile device&#39;s
                bluetooth, camera, microphone, sensors, social media accounts,
                storage, contacts, and other features. If you wish to change our
                access or permissions, you may do so in your device&#39;s
                settings.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Mobile Device Data.</span>
              <span class="c5 c0">
                &nbsp;We automatically collect device information (such as your
                mobile device ID, model, and manufacturer), operating system,
                version information and system configuration information, device
                and application identification numbers, browser type and
                version, hardware model Internet service provider and/or mobile
                carrier, and Internet Protocol (IP) address (or proxy server).
                If you are using our application(s), we may also collect
                information about the phone network associated with your mobile
                device, your mobile device&rsquo;s operating system or platform,
                the type of mobile device you use, your mobile device&rsquo;s
                unique device ID, and information about the features of our
                application(s) you accessed.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Push Notifications.</span>
              <span class="c5 c0">
                &nbsp;We may request to send you push notifications regarding
                your account or certain features of the application(s). If you
                wish to opt out from receiving these types of communications,
                you may turn them off in your device&#39;s settings.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </p>
          <h3 class="c19" >
            <span class="c26 c21 c11">Information automatically collected</span>
          </h3>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c0 c4">
              Some information &mdash; such as your Internet Protocol (IP)
              address and/or browser and device characteristics &mdash; is
              collected automatically when you visit our Services.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c5">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">The information we collect includes:</span>
          </p>
          <ul class="c16 lst-kix_cyztws2yynp7-0 start">
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Log and Usage Data.</span>
              <span class="c5 c0">
                &nbsp;Log and usage data is service-related, diagnostic, usage,
                and performance information our servers automatically collect
                when you access or use our Services and which we record in log
                files. Depending on how you interact with us, this log data may
                include your IP address, device information, browser type, and
                settings and information about your activity in the Services
                (such as the date/time stamps associated with your usage, pages
                and files viewed, searches, and other actions you take such as
                which features you use), device event information (such as
                system activity, error reports (sometimes called &quot;crash
                dumps&quot;), and hardware settings).
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c12 c0">Device Data.</span>
              <span class="c5 c0">
                &nbsp;We collect device data such as information about your
                computer, phone, tablet, or other device you use to access the
                Services. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile
                carrier, operating system, and system configuration information.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c0 c12">Location Data.</span>
              <span class="c5 c0">
                &nbsp;We collect location data such as information about your
                device&#39;s location, which can be either precise or imprecise.
                How much information we collect depends on the type and settings
                of the device you use to access the Services. For example, we
                may use GPS and other technologies to collect geolocation data
                that tells us your current location (based on your IP address).
                You can opt out of allowing us to collect this information
                either by refusing access to the information or by disabling
                your Location setting on your device. However, if you choose to
                opt out, you may not be able to use certain aspects of the
                Services.
              </span>
            </li>
          </ul>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <h2 class="c25" >
            <span class="c10">2. HOW DO WE PROCESS YOUR INFORMATION?</span>
          </h2>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </span>
          </p>
          <p class="c3">
            <span class="c15 c0 c11">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </p>
          <ul class="c16 lst-kix_ggqdmkqco7sk-0 start">
            <li class="c6 li-bullet-0">
              <span class="c0 c11">
                To facilitate account creation and authentication and otherwise
                manage user accounts.{" "}
              </span>
              <span class="c5 c0">
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c0 c11">
                To save or protect an individual&#39;s vital interest.
              </span>
              <span class="c5 c0">
                &nbsp;We may process your information when necessary to save or
                protect an individual&rsquo;s vital interest, such as to prevent
                harm.
              </span>
            </li>
          </ul>
          <h2 class="c25" >
            <span class="c10">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e., legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfill our
              contractual obligations, to protect your rights, or to fulfill our
              legitimate business interests.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c8">
              If you are located in the EU or UK, this section applies to you.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </span>
          </p>
          <ul class="c16 lst-kix_gsz8v6xjh8gc-0 start">
            <li class="c6 li-bullet-0">
              <span class="c0 c11">Consent. </span>
              <span class="c5 c0">
                We may process your information if you have given us permission
                (i.e., consent) to use your personal information for a specific
                purpose. You can withdraw your consent at any time.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c0 c11">Legal Obligations.</span>
              <span class="c5 c0">
                &nbsp;We may process your information where we believe it is
                necessary for compliance with our legal obligations, such as to
                cooperate with a law enforcement body or regulatory agency,
                exercise or defend our legal rights, or disclose your
                information as evidence in litigation in which we are involved.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c0 c11">Vital Interests.</span>
              <span class="c5 c0">
                &nbsp;We may process your information where we believe it is
                necessary to protect your vital interests or the vital interests
                of a third party, such as situations involving potential threats
                to the safety of any person.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c8 c0 highlight">
              If you are located in Canada, this section applies to you.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </span>
          </p>
          <ul class="c16 lst-kix_zak1mcqzd2zl-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                For investigations and fraud detection and prevention
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                For business transactions provided certain conditions are met
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                If the information is publicly available and is specified by the
                regulations
              </span>
            </li>
          </ul>
          <h2 class="c25" >
            <span class="c10">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short:</span>
            <span class="c4 c0">
              &nbsp;We may share information in specific situations described in
              this section and/or with the following categories of third
              parties.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11">
              Vendors, Consultants, and Other Third-Party Service Providers.
            </span>
            <span class="c0">
              &nbsp;We may share your data with third-party vendors, service
              providers, contractors, or agents (&quot;
            </span>
            <span class="c0 c11">third parties</span>
            <span class="c5 c0">
              &quot;) who perform services for us or on our behalf and require
              access to such information to do that work. We have contracts in
              place with our third parties, which are designed to help safeguard
              your personal information. This means that they cannot do anything
              with your personal information unless we have instructed them to
              do it. They will also not share your personal information with any
              organization apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct. The categories of third parties we may share personal
              information with are as follows:
            </span>
          </p>
          <ul class="c16 lst-kix_62q5w5g6427m-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Cloud Computing Services</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Payment Processors</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Data Analytics Services</span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              We also may need to share your personal information in the
              following situations:
            </span>
          </p>
          <ul class="c16 lst-kix_qszt24woh0j7-0 start">
            <li class="c6 li-bullet-0">
              <span class="c0 c11">Business Transfers.</span>
              <span class="c5 c0">
                &nbsp;We may share or transfer your information in connection
                with, or during negotiations of, any merger, sale of company
                assets, financing, or acquisition of all or a portion of our
                business to another company.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c0 c11">Offer Wall. </span>
              <span class="c5 c0">
                Our application(s) may display a third-party hosted &quot;offer
                wall.&quot; Such an offer wall allows third-party advertisers to
                offer virtual currency, gifts, or other items to users in return
                for the acceptance and completion of an advertisement offer.
                Such an offer wall may appear in our application(s) and be
                displayed to you based on certain data, such as your geographic
                area or demographic information. When you click on an offer
                wall, you will be brought to an external website belonging to
                other persons and will leave our application(s). A unique
                identifier, such as your user ID, will be shared with the offer
                wall provider in order to prevent fraud and properly credit your
                account with the relevant reward.
              </span>
            </li>
          </ul>
          <h2 class="c25" >
            <span class="c10">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              If you choose to register or log in to our Services using a social
              media account, we may have access to certain information about
              you.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required
              by law.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              We aim to protect your personal information through a system of
              organizational and technical security measures.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">8. DO WE COLLECT INFORMATION FROM MINORS?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short:</span>
            <span class="c4 c0">
              &nbsp;We do not knowingly collect data from or market to children
              under 18 years of age.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent&rsquo;s use of the Services.
              If we learn that personal information from users less than 18
              years of age has been collected, we will deactivate the account
              and take reasonable measures to promptly delete such data from our
              records. If you become aware of any data we may have collected
              from children under age 18, please contact us at
              hello@trainfitness.ai.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">9. WHAT ARE YOUR PRIVACY RIGHTS?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short:</span>
            <span class="c4 c0">
              &nbsp;In some regions, such as the European Economic Area (EEA),
              United Kingdom (UK), Switzerland, and Canada, you have rights that
              allow you greater access to and control over your personal
              information. You may review, change, or terminate your account at
              any time.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </span>
          </p>
          <p class="c3">
            <span class="c0">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1708839853045404&amp;usg=AOvVaw2ut7PrPDDw2n-Tr9HCmxhI"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1708839853045758&amp;usg=AOvVaw2tlZd9rCloE3Q9xuG8k7H1"
              >
                Member State data protection authority
              </a>
            </span>
            <span class="c0">&nbsp;or</span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1708839853046045&amp;usg=AOvVaw1MBQ8v3sn3BzfXU98JEYl2"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/&amp;sa=D&amp;source=editors&amp;ust=1708839853046318&amp;usg=AOvVaw1Tppc_hdi8MTMvcko3YFgy"
              >
                UK data protection authority
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c0">
              If you are located in Switzerland, you may contact the
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1708839853046700&amp;usg=AOvVaw0-LMVTdtQ5ByOZlj18nksO"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1708839853046912&amp;usg=AOvVaw2ePhoXjuT_1yTkuq2F4XLW"
              >
                Federal Data Protection and Information Commissioner
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c0 c11 c35">Withdrawing your consent:</span>
            <span class="c5 c0">
              &nbsp;If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section
              &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below or
              updating your preferences.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </span>
          </p>
          <p class="c3">
            <span class="c35 c0 c11">
              Opting out of marketing and promotional communications:{" "}
            </span>
            <span class="c5 c0">
              You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send, or by contacting us using the details
              provided in the section &quot;HOW CAN YOU CONTACT US ABOUT THIS
              NOTICE?&quot; below. You will then be removed from the marketing
              lists. However, we may still communicate with you &mdash; for
              example, to send you service-related messages that are necessary
              for the administration and use of your account, to respond to
              service requests, or for other non-marketing purposes.
            </span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">Account Information</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </span>
          </p>
          <ul class="c16 lst-kix_i717mzu6qxb2-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Log in to your account settings and update your user account.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you have questions or comments about your privacy rights, you
              may email us at hello@trainfitness.ai.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
          </h2>
          <p class="c3">
            <span class="c5 c0">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (&quot;DNT&quot;) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As
              such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">
              11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              If you are a resident of California, Colorado, Connecticut, Utah
              or Virginia, you are granted specific rights regarding access to
              your personal information.
            </span>
          </p>
          <p class="c3">
            <span class="c15 c0 c11">
              What categories of personal information do we collect?
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </span>
          </p>
          <a ></a>
          <a ></a>
          <table class="c7">
            <tr class="c36">
              <td class="c29" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c11">Category</span>
                </p>
              </td>
              <td class="c33" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c11">Examples</span>
                </p>
              </td>
              <td class="c38" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c11">Collected</span>
                </p>
              </td>
            </tr>
            <tr class="c23">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">A. Identifiers</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Contact details, such as real name, alias, postal address,
                    telephone or mobile contact number, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, and account name
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c34">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    B. Personal information as defined in the California
                    Customer Records statute
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Name, contact information, education, employment, employment
                    history, and financial information
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c34">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    C. Protected classification characteristics under state or
                    federal law
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">Gender and date of birth</span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c30">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">D. Commercial information</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Transaction information, purchase history, financial
                    details, and payment information
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c9">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">E. Biometric information</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">Fingerprints and voiceprints</span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c34">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    F. Internet or other similar network activity
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Browsing history, search history, online behavior, interest
                    data, and interactions with our and other websites,
                    applications, systems, and advertisements
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c9">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">G. Geolocation data</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">Device location</span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c37">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    H. Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Images and audio, video or call recordings created in
                    connection with our business activities
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c34">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    I. Professional or employment-related information
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Business contact details in order to provide you our
                    Services at a business level or job title, work history, and
                    professional qualifications if you apply for a job with us
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c9">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">J. Education Information</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Student records and directory information
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c34">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    K. Inferences drawn from collected personal information
                  </span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">
                    Inferences drawn from any of the collected personal
                    information listed above to create a profile or summary
                    about, for example, an individual&rsquo;s preferences and
                    characteristics
                  </span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c30">
              <td class="c14" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">L. Sensitive personal Information</span>
                </p>
              </td>
              <td class="c24" colspan="1" rowspan="1">
                <p class="c3">
                  <span class="c5 c0">Biometric data and health data</span>
                </p>
              </td>
              <td class="c13" colspan="1" rowspan="1">
                <p class="c20">
                  <span class="c5 c0">YES</span>
                </p>
              </td>
            </tr>
          </table>
          <p class="c3">
            <span class="c5 c0">
              We will use and retain the collected personal information as
              needed to provide the Services or for:
            </span>
          </p>
          <ul class="c16 lst-kix_1r2bysbdjqr0-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category A - As long as the user has an account with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category B - As long as the user has an account with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category C - As long as the user has an account with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category G - As long as the user has an account with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category H - As long as the user has an account with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Category L - As long as the user has an account with us
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              Category L information may be used, or disclosed to a service
              provider or contractor, for additional, specified purposes. You
              have the right to limit the use or disclosure of your sensitive
              personal information.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </span>
          </p>
          <ul class="c16 lst-kix_meu1mlnbjlko-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Receiving help through our customer support channels;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Participation in customer surveys or contests; and
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c15 c0 c11">
              How do we use and share your personal information?
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Learn about how we use your personal information in the section,
              &quot;HOW DO WE PROCESS YOUR INFORMATION?&quot;
            </span>
          </p>
          <p class="c3">
            <span class="c0 c11 c15">
              Will your information be shared with anyone else?
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information to in the section, &quot;WHEN AND WITH WHOM DO WE
              SHARE YOUR PERSONAL INFORMATION?&quot;
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be &quot;selling&quot; of your personal information.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We have not sold or shared any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months. We have disclosed the following categories of
              personal information to third parties for a business or commercial
              purpose in the preceding twelve (12) months:
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
              INFORMATION?&quot;
            </span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">California Residents</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              California Civil Code Section 1798.83, also known as the
              &quot;Shine The Light&quot; law permits our users who are
              California residents to request and obtain from us, once a year
              and free of charge, information about categories of personal
              information (if any) we disclosed to third parties for direct
              marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems
              (e.g., backups, etc.).
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c0 c11 c32">CCPA Privacy Notice</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              This section applies only to California residents. Under the
              California Consumer Privacy Act (CCPA), you have the rights listed
              below.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              The California Code of Regulations defines a &quot;residents&quot;
              as:
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              All other individuals are defined as &quot;non-residents.&quot;
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If this definition of &quot;resident&quot; applies to you, we must
              adhere to certain rights and obligations regarding your personal
              information.
            </span>
          </p>
          <p class="c3">
            <span class="c15 c0 c11">
              Your rights with respect to your personal data
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">
              Right to request deletion of the data &mdash; Request to delete
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">
              Right to be informed &mdash; Request to know
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Depending on the circumstances, you have a right to know:
            </span>
          </p>
          <ul class="c16 lst-kix_j533cypidcmz-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                whether we collect and use your personal information;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the categories of personal information that we collect;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the purposes for which the collected personal information is
                used;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                whether we sell or share personal information to third parties;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the categories of personal information that we sold, shared, or
                disclosed for a business purpose;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the categories of third parties to whom the personal information
                was sold, shared, or disclosed for a business purpose;
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the business or commercial purpose for collecting, selling, or
                sharing personal information; and
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the specific pieces of personal information we collected about
                you.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </span>
          </p>
          <p class="c3">
            <span class="c0 c17 underline">
              Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
              Privacy Rights
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We will not discriminate against you if you exercise your privacy
              rights.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If the business collects any of the following:
            </span>
          </p>
          <ul class="c16 lst-kix_vyvr8awoqyhj-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                social security information, drivers&#39; licenses, state ID
                cards, passport numbers
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">account login information</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                credit card numbers, financial account information, or
                credentials allowing access to such accounts
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">precise geolocation</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                racial or ethnic origin, religious or philosophical beliefs,
                union membership
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                the contents of email and text, unless the business is the
                intended recipient of the communication
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                genetic data, biometric data, and health data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                data concerning sexual orientation and sex life
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              you have the right to direct that business to limit its use of
              your sensitive personal information to that use which is necessary
              to perform the Services.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Once a business receives your request, they are no longer allowed
              to use or disclose your sensitive personal information for any
              other purpose unless you provide consent for the use or disclosure
              of sensitive personal information for additional purposes.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Please note that sensitive personal information that is collected
              or processed without the purpose of inferring characteristics
              about a consumer is not covered by this right, as well as the
              publicly available information.
            </span>
          </p>
          <p class="c3">
            <span class="c0">
              To exercise your right to limit use and disclosure of sensitive
              personal information, please email hello@trainfitness.ai or visit:
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://trainfitness.ai"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://trainfitness.ai"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">Verification process</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g., phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">Other privacy rights</span>
          </p>
          <ul class="c16 lst-kix_gr9a2fgwbih3-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                You may object to the processing of your personal information.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                You can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an
                authorized agent that does not submit proof that they have been
                validly authorized to act on your behalf in accordance with the
                CCPA.
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                You may request to opt out from future selling or sharing of
                your personal information to third parties. Upon receiving an
                opt-out request, we will act upon the request as soon as
                feasibly possible, but no later than fifteen (15) days from the
                date of the request submission.
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0">
              To exercise these rights, you can contact us by visiting
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://trainfitness.ai"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://trainfitness.ai/"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">
              , by email at hello@trainfitness.ai, or by referring to the
              contact details at the bottom of this document. If you have a
              complaint about how we handle your data, we would like to hear
              from you.
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">Colorado Residents</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              This section applies only to Colorado residents. Under the
              Colorado Privacy Act (CPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </span>
          </p>
          <ul class="c16 lst-kix_3hakpmac47ev-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to be informed whether or not we are processing your
                personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Right to access your personal data</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to correct inaccuracies in your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to request deletion of your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to obtain a copy of the personal data you previously
                shared with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects (&quot;profiling&quot;)
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0">
              To submit a request to exercise these rights described above,
              please email hello@trainfitness.ai or visit
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853076387&amp;usg=AOvVaw06xYCloGgufTakiFsulxIi"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853076785&amp;usg=AOvVaw1-JROOCF52FyvC_9XW3o5w"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at hello@trainfitness.ai.
              Within forty-five (45) days of receipt of an appeal, we will
              inform you in writing of any action taken or not taken in response
              to the appeal, including a written explanation of the reasons for
              the decisions.
            </span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">Connecticut Residents</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              This section applies only to Connecticut residents. Under the
              Connecticut Data Privacy Act (CTDPA), you have the rights listed
              below. However, these rights are not absolute, and in certain
              cases, we may decline your request as permitted by law.
            </span>
          </p>
          <ul class="c16 lst-kix_4mbjg0a1muts-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to be informed whether or not we are processing your
                personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Right to access your personal data</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to correct inaccuracies in your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to request deletion of your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to obtain a copy of the personal data you previously
                shared with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects (&quot;profiling&quot;)
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0">
              To submit a request to exercise these rights described above,
              please email hello@trainfitness.ai or visit
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853078430&amp;usg=AOvVaw12F0bnauz99TQtERCd5MzI"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853078702&amp;usg=AOvVaw2lYARckeSbivZUXsqsYclP"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If we decline to take action regarding your request and you wish
              to appeal our decision, please email us at hello@trainfitness.ai.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions.
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">Utah Residents</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              This section applies only to Utah residents. Under the Utah
              Consumer Privacy Act (UCPA), you have the rights listed below.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law.
            </span>
          </p>
          <ul class="c16 lst-kix_nlvx4x9tdwqx-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to be informed whether or not we are processing your
                personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Right to access your personal data</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to request deletion of your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to obtain a copy of the personal data you previously
                shared with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising or the sale of personal data
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c0">
              To submit a request to exercise these rights described above,
              please email hello@trainfitness.ai or visit
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853080246&amp;usg=AOvVaw2IDcClaXt4IO57GefIM9Iu"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853080486&amp;usg=AOvVaw2hwLVxgzKQO228n13WxELo"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c15 c11 c21 highlight">Virginia Residents</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Under the Virginia Consumer Data Protection Act (VCDPA):
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              &quot;Consumer&quot; means a natural person who is a resident of
              the Commonwealth acting only in an individual or household
              context. It does not include a natural person acting in a
              commercial or employment context.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              &quot;Personal data&quot; means any information that is linked or
              reasonably linkable to an identified or identifiable natural
              person. &quot;Personal data&quot; does not include de-identified
              data or publicly available information.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              &quot;Sale of personal data&quot; means the exchange of personal
              data for monetary consideration.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If this definition of &quot;consumer&quot; applies to you, we must
              adhere to certain rights and obligations regarding your personal
              data.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">
              Your rights with respect to your personal data
            </span>
          </p>
          <ul class="c16 lst-kix_phahi1g1i622-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to be informed whether or not we are processing your
                personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">Right to access your personal data</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to correct inaccuracies in your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to request deletion of your personal data
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to obtain a copy of the personal data you previously
                shared with us
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                Right to opt out of the processing of your personal data if it
                is used for targeted advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects (&quot;profiling&quot;)
              </span>
            </li>
          </ul>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">
              Exercise your rights provided under the Virginia VCDPA
            </span>
          </p>
          <p class="c3">
            <span class="c0">
              You may contact us by email at hello@trainfitness.ai or visit
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853082805&amp;usg=AOvVaw0UmtEv6pOVOKfFcuBtrSux"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853083086&amp;usg=AOvVaw0akIXkec1QDZJVM7T-3jkb"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you are using an authorized agent to exercise your rights, we
              may deny a request if the authorized agent does not submit proof
              that they have been validly authorized to act on your behalf.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">Verification process</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer&#39;s request. If you
              submit the request through an authorized agent, we may need to
              collect additional information to verify your identity before
              processing your request.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              Upon receiving your request, we will respond without undue delay,
              but in all cases, within forty-five (45) days of receipt. The
              response period may be extended once by forty-five (45) additional
              days when reasonably necessary. We will inform you of any such
              extension within the initial 45-day response period, together with
              the reason for the extension.
            </span>
          </p>
          <p class="c3">
            <span class="c17 c0 underline">Right to appeal</span>
          </p>
          <p class="c3">
            <span class="c0">
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at hello@trainfitness.ai.
              Within sixty (60) days of receipt of an appeal, we will inform you
              in writing of any action taken or not taken in response to the
              appeal, including a written explanation of the reasons for the
              decisions. If your appeal is denied, you may contact the
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint&amp;sa=D&amp;source=editors&amp;ust=1708839853084285&amp;usg=AOvVaw2t1sOpJZVhPDpD6tcodbeu"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint&amp;sa=D&amp;source=editors&amp;ust=1708839853084554&amp;usg=AOvVaw0-LImjAn6LTKFzAl4JNWcy"
              >
                Attorney General to submit a complaint
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <h2 class="c25" >
            <span class="c10">
              12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </h2>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c12 c0 c11">In Short:</span>
            <span class="c4 c0">
              &nbsp;You may have additional rights based on the country you
              reside in.
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3 highlight">
            <span class="c11">Australia</span>
            <span>&nbsp;</span>
            <span class="c11">and</span>
            <span>&nbsp;</span>
            <span class="c15 c21 c11">New Zealand</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We collect and process your personal information under the
              obligations and conditions set by Australia&#39;s Privacy Act 1988
              and New Zealand&#39;s Privacy Act 2020 (Privacy Act).
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              This privacy notice satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </span>
          </p>
          <ul class="c16 lst-kix_1kc3nx8wyu1g-0 start">
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                offer you the products or services that you want
              </span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">respond to or help with your requests</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">manage your account with us</span>
            </li>
            <li class="c6 li-bullet-0">
              <span class="c5 c0">
                confirm your identity and protect your account
              </span>
            </li>
          </ul>
          <p class="c3">
            <span class="c5 c0">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              &quot;HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?&quot;
            </span>
          </p>
          <p class="c3">
            <span>I</span>
            <span class="c0">
              f you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us&amp;sa=D&amp;source=editors&amp;ust=1708839853087056&amp;usg=AOvVaw2oxRX8T9N-j-OCjJ3CTrXc"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us&amp;sa=D&amp;source=editors&amp;ust=1708839853087367&amp;usg=AOvVaw0BKM4GUEGZC6voBJWLovb1"
              >
                Office of the Australian Information Commissioner
              </a>
            </span>
            <span class="c0">
              &nbsp;and a breach of New Zealand&#39;s Privacy Principles to the
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.privacy.org.nz/your-rights/making-a-complaint/&amp;sa=D&amp;source=editors&amp;ust=1708839853087676&amp;usg=AOvVaw0VJFjcLYeu-Unq3JT1wRPi"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://www.privacy.org.nz/your-rights/making-a-complaint/&amp;sa=D&amp;source=editors&amp;ust=1708839853087927&amp;usg=AOvVaw3qDq153_WN89gG6u0-OS6b"
              >
                Office of New Zealand Privacy Commissioner
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c15 c21 c11 highlight">Republic of South Africa</span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              &quot;HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?&quot;
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </span>
          </p>
          <p class="c3">
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://inforegulator.org.za/&amp;sa=D&amp;source=editors&amp;ust=1708839853088785&amp;usg=AOvVaw22Nu8WM9-ak2oiOzTEKBE-"
              >
                The Information Regulator (South Africa)
              </a>
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              General enquiries: enquiries@inforegulator.org.za
            </span>
          </p>
          <p class="c3">
            <span class="c0">
              Complaints (complete POPIA/PAIA form 5):
              PAIAComplaints@inforegulator.org.za &amp;{" "}
            </span>
            <span class="c1">POPIAComplaints@inforegulator.org.za</span>
          </p>
          <h2 class="c25" >
            <span class="c10">13. DO WE MAKE UPDATES TO THIS NOTICE?</span>
          </h2>
          <p class="c3">
            <span class="c12 c0 c11">In Short: </span>
            <span class="c4 c0">
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </span>
          </p>
          <p class="c3 c22">
            <span class="c1"></span>
          </p>
          <p class="c3">
            <span class="c5 c0">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated &quot;Revised&quot; date
              and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy notice, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy notice frequently to be informed of how
              we are protecting your information.
            </span>
          </p>
          <h2 class="c25" >
            <span class="c10">
              14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </h2>
          <p class="c3">
            <span class="c5 c0">
              If you have questions or comments about this notice, you may email
              us at hello@trainfitness.ai or contact us by post at:
            </span>
          </p>
          <p class="c3">
            <span class="c5 c0">Train Fitness Inc.</span>
          </p>
          <p class="c3">
            <span class="c5 c0">602-130 Spadina Avenue</span>
          </p>
          <p class="c3">
            <span class="c5 c0">Toronto, Ontario M5V 2L4</span>
          </p>
          <p class="c3">
            <span class="c5 c0">Canada</span>
          </p>
          <h2 class="c25" >
            <span class="c10">
              15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </h2>
          <p class="c3">
            <span class="c0">
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please visit:
            </span>
            <span class="c0">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853090979&amp;usg=AOvVaw2qVZFykW7QTKQkSbuu1uYa"
              >
                &nbsp;
              </a>
            </span>
            <span class="c2">
              <a
                class="c18"
                href="https://www.google.com/url?q=https://trainfitness.ai/&amp;sa=D&amp;source=editors&amp;ust=1708839853091240&amp;usg=AOvVaw1ZPA0416r9vMRtITmtlsdZ"
              >
                https://trainfitness.ai/
              </a>
            </span>
            <span class="c5 c0">.</span>
          </p>
          <p class="c3 c22">
            <span class="c15 c21 c11 highlight"></span>
          </p>
          <p class="c22 c31">
            <span class="c1"></span>
          </p>
        </div>
      </div>
    </Container>
  );
}

export default PrivacyPolicy;

import React from "react";
import { Row, Col } from "reactstrap";
import "./WorkoutOverview.css";
import { convertSecondsToTimeString } from "../../../utils";

const WorkoutOverviewLine = ({ heading, value, isLast }) => (
  <div className="workout-overview-line">
    <p className="workout-overview-heading">{heading}</p>
    <p
      className="workout-overview-value"
      style={isLast ? { marginBottom: 0 } : {}}
    >
      {value}
    </p>
    <hr />
  </div>
);

const WorkoutDetailOverview = (props) => {
  const durationString = convertSecondsToTimeString(
    props.workoutData.timeActive
  );

  return (
    <div className="workout-overview">
      <Row>
        <p className="workout-overview-header">Overview</p>
      </Row>
      <Row>
        <div className="workout-overview-custom-div">
          <WorkoutOverviewLine heading="Duration" value={durationString} />
          <WorkoutOverviewLine
            heading="Total Volume Lifted, lbs"
            value={Math.ceil(props.workoutData.workoutTvl).toLocaleString()}
          />
          <WorkoutOverviewLine
            heading="Calories"
            value={Math.ceil(
              props.workoutData.workoutCalories
            ).toLocaleString()}
          />
          <WorkoutOverviewLine
            heading="Sets"
            value={props.workoutData.totalSets}
            isLast
          />
        </div>
      </Row>
    </div>
  );
};

export default WorkoutDetailOverview;

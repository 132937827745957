import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import WorkoutHeader from "./WorkoutHeader";
import WorkoutOverview from "./WorkoutOverview";
import WorkoutPictures from "./WorkoutPictures";
import WorkoutLog from "./WorkoutLog";
import Loader from "../../Loader";
import PrivateWorkout from "./PrivateWorkout";
import ErrorPage from "../../ErrorPage";
import "./Detail.css";

const WorkoutDetail = () => {
  const match = useRouteMatch();
  const workoutID = match.params.workoutId;

  const [ready, setReady] = React.useState(false);
  const [isPrivate, setIsPrivate] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);
  const [workoutData, setWorkoutData] = React.useState({});

  useEffect(() => {
    axios
      .get(
        // `http://127.0.0.1:5001/train-fitness-305518/us-central1/api/v2/public/workout/${workoutID}`
        `https://backend.trainfitness.ai/v2/public/workout/${workoutID}`
      )
      .then((response) => {
        // console.log(response.data);
        setWorkoutData(response.data);
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 404) {
          setNotFound(true);
        }

        if (error.response.status === 403) {
          setIsPrivate(true);
        }
        setReady(true);
      });
  }, []);

  if (!ready) {
    return <Loader />;
  }

  if (isPrivate) {
    return <PrivateWorkout />;
  }

  if (notFound) {
    return <ErrorPage />;
  }

  return (
    <div className="workout-detail">
      <Header />
      <WorkoutHeader workoutData={workoutData} />
      <WorkoutOverview workoutData={workoutData} />
      <WorkoutPictures workoutData={workoutData} />
      <WorkoutLog workoutData={workoutData} />
      <Footer workoutData={workoutData} />
    </div>
  );
};

export default WorkoutDetail;

import React, { useState } from "react";
import "./style.scss";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import closeIcon from "./../../Images/close_icon.svg";
import trainIcon from "./../../Images/train_icon.png";
// import joinQR from "./../../Images/join_qr.png";
import joinQR from "./../../Images/join_qr_v2.png";

import { Formik } from "formik";
import { useRecoilState } from "recoil";
import { joinModalState } from "../../recoil/atoms";
import {
  AsYouType,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";

function JoinModal() {
  const [_joinModalState, setJoinModalState] = useRecoilState(joinModalState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  function modalToggle() {
    setJoinModalState(!_joinModalState);
  }
  function sendSMSTwilio({ to, text }) {
    const url = process.env.REACT_APP_SEND_DISCOUNT_API;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "phoneNumber": to,
      "textMessage": text
    });

    const options = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };

    return new Promise((resolve, reject) => {
      return fetch(url, options)
        .then((response) => {
          return resolve(response);
        })
        .then((responseJson) => {
          return resolve(responseJson);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }
  return (
    <>
      <Modal
        isOpen={_joinModalState}
        toggle={modalToggle}
        className="join-modal"
      >
        <ModalHeader className="justify-content-end">
          <img
            src={closeIcon}
            className="modal-close-icon"
            onClick={modalToggle}
            alt="Close modal"
          />
        </ModalHeader>
        <ModalBody>
          <div className="join-container">
            <img
              src={trainIcon}
              className="join-train-icon"
              onClick={modalToggle}
              alt="Train logo"
            />
            <h2 className="join-title">Join Train Fitness</h2>
            <p className="join-description">
              Enter your number and we’ll send you a 30% discount code for Train
              Fitness.
            </p>
          </div>

          <Formik
            initialValues={{
              mobile: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.mobile) {
                errors.mobile = "Required";
              } else if (!isValidPhoneNumber(values.mobile, "US")) {
                errors.mobile = "Invalid mobile no";
              }
              return errors;
            }}
            onSubmit={async ({ mobile }) => {
              try {
                const phoneNumber = parsePhoneNumber(mobile, "US");
                if (phoneNumber) {
                  const dataRes = await sendSMSTwilio({
                    text: "Here's your 30% off discount link for Train Fitness - https://apps.apple.com/redeem?ctx=offercodes&id=1548577496&code=BARBELLWORKOUTS                    ",
                    to: phoneNumber?.number,
                  });
                }
              } catch (err) {
                console.log("Error SMS sender", err);
              } finally {
                setIsSubmitted(true);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue,
            }) => (
              <div>
                <Input
                  className={"tel-field text-center"}
                  placeholder="(000)   000   0000"
                  name="mobile"
                  id="mobile"
                  onChange={(event) => {
                    const val = new AsYouType("US").input(
                      event.currentTarget.value
                    );
                    setFieldValue("mobile", val);
                  }}
                  onBlur={handleBlur}
                  value={values.mobile}
                />
                <Button
                  type="button"
                  className={"join-submit-btn"}
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  {isSubmitting
                    ? "Sending"
                    : isSubmitted
                      ? "Sent!  Click Here to Resend"
                      : "Send"}
                </Button>
              </div>
            )}
          </Formik>
          <div className="qr-divider"></div>
          <div className="qr-sec-container  d-sm-flex  justify-content-between">
            <img src={joinQR} className="join-qr" alt="QR code" />
            <p className="qr-description">
              Or, scan this QR code on your iPhone to access this offer
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default JoinModal;

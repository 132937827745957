import React, { useEffect } from "react";
import { OperatingSystemTypes, getMobileOperatingSystem } from "../../../utils";
import WebHome from "./Web/Web";

function Home() {
  const operatingSystem = getMobileOperatingSystem();
  return (
    <div className="">
      {operatingSystem == OperatingSystemTypes.IOS ? (
        <div>iOS</div>
      ) : operatingSystem == OperatingSystemTypes.ANDROID ? (
        <div>Android</div>
      ) : (
        <WebHome />
      )}
    </div>
  );
}

export default Home;

import React, { useEffect } from "react";
// import userData from "./mock.json";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import "./index.css";
import useScreenSize from "../../utils/screenHook";
import axios from "axios";
import Loader from "../Loader";
import ErrorPage from "../ErrorPage";

const ProfileView = (props) => {
  const [ready, setReady] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const match = useRouteMatch();
  const screenSize = useScreenSize();
  const isBigScreen = screenSize.width > 768;
  useEffect(() => {
    axios
      .get(`https://backend.trainfitness.ai/v2/public/user-profile/${username}`)
      .then((response) => {
        // console.log(response.data);
        setUserData(response.data);
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        setReady(true);
      });
  }, []);

  const username = match.params.username;
  const onClick = () => {
    window.location.href = `https://apps.apple.com/ca/app/train-fitness-workout-tracker/id1548577496`;
  };

  if (!ready) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className="view">
      <div className="content-view">
        <div className="logo-container">
          <img src="/images/train-logo-profile.png" alt="logo" height="32px" />
        </div>
        <div className="profile">
          {/* <img className="profile-img" src={userData.image} alt={userData.name} /> */}
          {/* <img className="profile-img" src="./images/sample-user-img.png" alt={userData.name} />
           */}
          <img
            className="profile-image"
            src={
              userData.profilePic
                ? userData.profilePic
                : "./images/sample-user-img.png"
            }
            alt={userData.name}
          />

          <h2 className="profile-title">{userData.name}</h2>
          <div className="profile-caption">{userData.bio}</div>

          <div className="profile-stats">
            <div className="profile-stat">
              <div className="profile-stat-number">{userData.workoutCount}</div>
              <div className="profile-stat-label">Workouts</div>
            </div>
            <div className="divider"></div>
            <div className="profile-stat">
              <div className="profile-stat-number">
                {userData.followersCount}
              </div>
              <div className="profile-stat-label">Followers</div>
            </div>
            <div className="divider"></div>
            <div className="profile-stat">
              <div className="profile-stat-number">
                {userData.followingCount}
              </div>
              <div className="profile-stat-label">Following</div>
            </div>
          </div>
        </div>
        <div className="profile-action">
          <button className="btn btn-primary" onClick={onClick}>
            Full Profile on Train Fitness
          </button>
        </div>
      </div>
      <div className="static-wave">
        <img
          src={isBigScreen ? "/images/wave-desktop.png" : "/images/wave.png"}
          alt="static-wave"
          h
        />
      </div>
    </div>
  );
};
export default ProfileView;

import React from "react";
import { Row, Col } from "reactstrap";
import "./WorkoutHeader.css";
import UserProfileImagePlaceholder from "../../../Images/user-profile-pic-placeholder.png";

const WorkoutDetailHeader = (props) => {
  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const date = new Date(props.workoutData.timeStarted * 1000);
  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <div className="workout-header">
      <Row>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <div style={{ paddingRight: "2%" }}>
            <img
              src={
                props.workoutData.userProfilePic
                  ? props.workoutData.userProfilePic
                  : UserProfileImagePlaceholder
              }
              alt="User Profile"
              className="workout-header-profile-image"
            />
          </div>
          <div style={{ paddingLeft: "2%" }}>
            <p className="workout-header-username">
              {props.workoutData.userName}
            </p>
            <p className="workout-header-time">{formattedDate}</p>
          </div>
        </div>
      </Row>
      {props.workoutData.workoutName ? (
        <Row>
          <p
            className={
              props.workoutData.caption
                ? "workout-header-name"
                : "workout-header-no-margin-bottom workout-header-name"
            }
          >
            {props.workoutData.workoutName}
          </p>
        </Row>
      ) : null}
      {props.workoutData.caption ? (
        <Row>
          <p className="workout-header-no-margin-bottom workout-header-caption">
            {props.workoutData.caption}
          </p>
        </Row>
      ) : null}
    </div>
  );
};

export default WorkoutDetailHeader;

const OperatingSystemTypes = Object.freeze({
  IOS: Symbol("iOS"),
  ANDROID: Symbol("Android"),
  WEB: Symbol("web"),
});

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', or 'web'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || window.opera;

  if (/android/i.test(userAgent)) {
    return OperatingSystemTypes.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OperatingSystemTypes.IOS;
  }

  return OperatingSystemTypes.WEB;
}

function convertSecondsToTimeString(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.ceil(seconds % 60);

  let timeString = "";
  if (hours > 0) {
    timeString += `${hours}h `;
  }
  if (minutes > 0) {
    timeString += `${minutes}m `;
  }
  if (remainingSeconds > 0) {
    timeString += `${remainingSeconds}s`;
  }

  return timeString.trim();
}

export {
  OperatingSystemTypes,
  getMobileOperatingSystem,
  convertSecondsToTimeString,
};

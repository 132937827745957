import React, { useEffect } from "react";
import "./styes.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Router from "../Router/Router";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useRecoilState, useSetRecoilState } from "recoil";
import { joinModalState, ouibouceHanderState } from "../../recoil/atoms";
import ouibounce from "ouibounce";
import ProfileView from "../Profile";
import IOSExclusive from "../iOSExclusive";
import WorkoutDetail from "../Workout/Detail/Detail";
import { isDesktop, isIOS } from "react-device-detect";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

function App() {
  const setJoinModalState = useSetRecoilState(joinModalState);
  const [ouibouceHander, setOuibouceHander] =
    useRecoilState(ouibouceHanderState);

  useEffect(() => {
    if (!ouibouceHander) {
      const handlerRef = ouibounce(false, {
        aggressive: false,
        cookieExpire: 30,
        cookieName: "joinModal",
        callback: function () {
          setJoinModalState(true);
        },
      });
      setOuibouceHander(handlerRef);
    }

    return () => (ouibouceHander ? ouibouceHander.disable() : null);
  }, [ouibouceHander]);


  const routeToWeb  = isDesktop || isIOS;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path={"/share/profile/:username/web"}>
          <ProfileView />
        </Route>
        <Route path={"/share/workout/:workoutId/web"}>
          <WorkoutDetail />
        </Route>
        <Route  path={"/share/workout/:workoutId"}
          render={(props) => {
            // if (routeToWeb) {
            //   return <Redirect to={`/share/workout/${props.match.params.workoutId}/web`} />
            // }else{
             return  <IOSExclusive />
            // }
          }
        }
        />

       
        <Route path={"/share/profile/:username"} render={(props) => {
          if (routeToWeb) {
            return <Redirect to={`/share/profile/${props.match.params.username}/web`} />
          }else{
            return <IOSExclusive />
          }
        }} />

        <Route path={["/workout-home", "/workout-detail"]}>
          <Router />
        </Route>
        <Route path={["/", "/privacy", "/terms"]}>
          <Header />
          <Router />
          <Footer />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

import { Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import React from "react";
import Terms from "../Terms/Terms";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import WorkoutHome from "../Workout/Home/Home";
import WorkoutDetail from "../Workout/Detail/Detail";
import Privacy from "../Terms/PrivacyCommitment";

function Router() {
  return (
    <Switch>
      <Route path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route path="/workout-home">
        <WorkoutHome />
      </Route>
      <Route path="/workout-detail">
        <WorkoutDetail />
      </Route>
      <Route path="/privacycommitment">
        <Privacy />
      </Route>
      
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default Router;

import { Container } from "reactstrap";
import "./style.scss";

function Terms() {
  return (
    <Container className="mt-5 mb-5 privacy_page">
      <h1>TERMS OF USE</h1>
      <div className="mt-3">
        <div>
          <p class="c1">
            <span class="c10 c4">Last updated February 24, 2024 &nbsp;</span>
          </p>
          <h2 class="c5" id="h.sd2kkgi0xy3c">
            <span class="c3">AGREEMENT TO OUR LEGAL TERMS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We are Train Fitness Inc. <b>(&quot;Company</b>,&quot; <b>&quot;we</b>,&quot;
              <b>&quot;us&quot;</b>, <b>&quot;our&quot;</b>), a company registered in Canada
              at 602-130 Spadina Avenue, Toronto, Ontario M5V 0H4.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We operate the mobile application Train Fitness Inc. (the
              &quot;<b>App</b>&quot;), as well as any other related products and
              services that refer or link to these legal terms (the &quot;<b>Legal</b>
              Terms&quot;) (collectively, the &quot;<b>Services</b>&quot;).
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              Train Fitness provides exercise tracking, social, and other
              workout features for anaerobic activity.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              You can contact us by email at hello@trainfitness.ai or by mail to
              602-130 Spadina Avenue, Toronto, Ontario M5V 0H4, Canada.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity
              (&quot;you&quot;), and Train Fitness Inc., concerning your access
              to and use of the Services. You agree that by accessing the
              Services, you have read, understood, and agreed to be bound by all
              of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
              TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
              AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              The Services are intended for users who are at least 18 years of
              age. All users who are minors in the jurisdiction in which they
              reside (generally under the age of 18) must have the permission
              of, and be directly supervised by, their parent or guardian to use
              the Services. If you are a minor, you must have your parent or
              guardian read and agree to these Legal Terms prior to you using
              the Services.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We recommend that you print a copy of these Legal Terms for your
              records.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We reserve the right to update and revise these Terms at any time.
              Train Fitness Inc. will ensure to update the &ldquo;Last
              Updated&rdquo; date included at the top of this page whenever
              modifications are made. Any such changes are effective immediately
              when we post them and apply to all access to and use of the
              Services thereafter. By continuing to use the Services after the
              effective date of any changes, you agree to be bound by the
              modified terms.
            </span>
          </p>
          <h2 class="c5" id="h.vte1nuaygqg5">
            <span class="c3">TABLE OF CONTENTS</span>
          </h2>
          <p class="c1">
            <span class="c10 c4">1. OUR SERVICES</span>
          </p>
          <p class="c1">
            <span class="c10 c4">2. INTELLECTUAL PROPERTY RIGHTS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">3. USER REPRESENTATIONS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">4. USER REGISTRATION</span>
          </p>
          <p class="c1">
            <span class="c10 c4">5. PURCHASES AND PAYMENT</span>
          </p>
          <p class="c1">
            <span class="c10 c4">6. SUBSCRIPTIONS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">7. SOFTWARE</span>
          </p>
          <p class="c1">
            <span class="c10 c4">8. PROHIBITED ACTIVITIES</span>
          </p>
          <p class="c1">
            <span class="c10 c4">9. USER GENERATED CONTRIBUTIONS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">10. CONTRIBUTION LICENSE</span>
          </p>
          <p class="c1">
            <span class="c10 c4">11. MOBILE APPLICATION LICENSE</span>
          </p>
          <p class="c1">
            <span class="c10 c4">12. SOCIAL MEDIA</span>
          </p>
          <p class="c1">
            <span class="c10 c4">13. SERVICES MANAGEMENT</span>
          </p>
          <p class="c1">
            <span class="c10 c4">14. PRIVACY POLICY</span>
          </p>
          <p class="c1">
            <span class="c10 c4">15. COPYRIGHT INFRINGEMENTS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">16. TERM AND TERMINATION</span>
          </p>
          <p class="c1">
            <span class="c10 c4">17. MODIFICATIONS AND INTERRUPTIONS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">18. GOVERNING LAW</span>
          </p>
          <p class="c1">
            <span class="c10 c4">19. DISPUTE RESOLUTION</span>
          </p>
          <p class="c1">
            <span class="c10 c4">20. CORRECTIONS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">21. DISCLAIMER</span>
          </p>
          <p class="c1">
            <span class="c10 c4">22. LIMITATIONS OF LIABILITY</span>
          </p>
          <p class="c1">
            <span class="c10 c4">23. NO MEDICAL ADVICE</span>
          </p>
          <p class="c1">
            <span class="c10 c4">24. INDEMNIFICATION</span>
          </p>
          <p class="c1">
            <span class="c10 c4">25. USER DATA</span>
          </p>
          <p class="c1">
            <span class="c10 c4">
              26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4">27. CALIFORNIA USERS AND RESIDENTS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">28. MISCELLANEOUS</span>
          </p>
          <p class="c1">
            <span class="c10 c4">29. CONTACT US</span>
          </p>
          <h2 class="c5" id="h.8v9oeuegtpsm">
            <span class="c3">1. OUR SERVICES</span>
          </h2>
          <p class="c1">
            <span class="c0">
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              The Services are not tailored to comply with industry-specific
              regulations (Health Insurance Portability and Accountability Act
              (HIPAA), Federal Information Security Management Act (FISMA),
              etc.), so if your interactions would be subjected to such laws,
              you may not use the Services. You may not use the Services in a
              way that would violate the Gramm-Leach-Bliley Act (GLBA).
            </span>
          </p>
          <h2 class="c5" id="h.it4wpemrvp1d">
            <span class="c3">2. INTELLECTUAL PROPERTY RIGHTS</span>
          </h2>
          <p class="c1">
            <span class="c10 c4 highlight2">Our intellectual property</span>
          </p>
          <p class="c1">
            <span class="c0">
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              &quot;Content&quot;), as well as the trademarks, service marks,
              and logos contained therein (the &quot;Marks&quot;).
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              Our Content and Marks are protected by copyright and trademark
              laws (and various other intellectual property rights and unfair
              competition laws) and treaties in the United States and around the
              world.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              The Content and Marks are provided in or through the Services
              &quot;AS IS&quot; for your personal, non-commercial use only.
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Your use of our Services</span>
          </p>
          <p class="c1">
            <span class="c0">
              Subject to your compliance with these Legal Terms, including the
              &quot;PROHIBITED ACTIVITIES&quot; section below, we grant you a
              non-exclusive, non-transferable, revocable license to:
            </span>
          </p>
          <ul class="c15 lst-kix_75yzb9qu4ppc-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">access the Services; and</span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                download or print a copy of any portion of the Content to which
                you have properly gained access.
              </span>
            </li>
          </ul>
          <p class="c1">
            <span class="c0">
              solely for your personal, non-commercial use.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to: hello@trainfitness.ai. If
              we ever grant you the permission to post, reproduce, or publicly
              display any part of our Services or Content, you must identify us
              as the owners or licensors of the Services, Content, or Marks and
              ensure that any copyright or proprietary notice appears or is
              visible on posting, reproducing, or displaying our Content.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Your submissions and contributions</span>
          </p>
          <p class="c1">
            <span class="c0">
              Please review this section and the &quot;PROHIBITED
              ACTIVITIES&quot; section carefully prior to using our Services to
              understand the (a) rights you give us and (b) obligations you have
              when you post or upload any content through the Services.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              <b>Submissions:</b> By directly sending us any question, comment,
              suggestion, idea, feedback, or other information about the
              Services (&quot;Submissions&quot;), you agree to assign to us all
              intellectual property rights in such Submission. You agree that we
              shall own this Submission and be entitled to its unrestricted use
              and dissemination for any lawful purpose, commercial or otherwise,
              without acknowledgment or compensation to you.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              <b>Contributions:</b> The Services may invite you to chat, contribute to,
              or participate in blogs, message boards, online forums, and other
              functionality during which you may create, submit, post, display,
              transmit, publish, distribute, or broadcast content and materials
              to us or through the Services, including but not limited to text,
              writings, video, audio, photographs, music, graphics, comments,
              reviews, rating suggestions, personal information, or other
              material (&quot;Contributions&quot;). Any Submission that is
              publicly posted shall also be treated as a Contribution.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              You understand that Contributions may be viewable by other users
              of the Services.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              <b>When you post Contributions, you grant us a license (including use
              of your name, trademarks, and logos):</b> By posting any
              Contributions, you grant us an unrestricted, unlimited,
              irrevocable, perpetual, non-exclusive, transferable, royalty-free,
              fully-paid, worldwide right, and license to: use, copy, reproduce,
              distribute, sell, resell, publish, broadcast, retitle, store,
              publicly perform, publicly display, reformat, translate, excerpt
              (in whole or in part), and exploit your Contributions (including,
              without limitation, your image, name, and voice) for any purpose,
              commercial, advertising, or otherwise, to prepare derivative works
              of, or incorporate into other works, your Contributions, and to
              sublicense the licenses granted in this section. Our use and
              distribution may occur in any media formats and through any media
              channels.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              This license includes our use of your name, company name, and
              franchise name, as applicable, and any of the trademarks, service
              marks, trade names, logos, and personal and commercial images you
              provide.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              <b>You are responsible for what you post or upload:</b> By sending us
              Submissions and/or posting Contributions through any part of the
              Services or making Contributions accessible through the Services
              by linking your account through the Services to any of your social
              networking accounts, you:
            </span>
          </p>
          <ul class="c15 lst-kix_pshjihanx550-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                confirm that you have read and agree with our &quot;PROHIBITED
                ACTIVITIES&quot; and will not post, send, publish, upload, or
                transmit through the Services any Submission nor post any
                Contribution that is illegal, harassing, hateful, harmful,
                defamatory, obscene, bullying, abusive, discriminatory,
                threatening to any person or group, sexually explicit, false,
                inaccurate, deceitful, or misleading;
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                to the extent permissible by applicable law, waive any and all
                moral rights to any such Submission and/or Contribution;
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                warrant that any such Submission and/or Contributions are
                original to you or that you have the necessary rights and
                licenses to submit such Submissions and/or Contributions and
                that you have full authority to grant us the above-mentioned
                rights in relation to your Submissions and/or Contributions; and
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                warrant and represent that your Submissions and/or Contributions
                do not constitute confidential information.
              </span>
            </li>
          </ul>
          <p class="c1">
            <span class="c0">
              You are solely responsible for your Submissions and/or
              Contributions and you expressly agree to reimburse us for any and
              all losses that we may suffer because of your breach of (a) this
              section, (b) any third party&rsquo;s intellectual property rights,
              or (c) applicable law.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We may remove or edit your Content: Although we have no obligation
              to monitor any Contributions, we shall have the right to remove or
              edit any Contributions at any time without notice if in our
              reasonable opinion we consider such Contributions harmful or in
              breach of these Legal Terms. If we remove or edit any such
              Contributions, we may also suspend or disable your account and
              report you to the authorities.
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Copyright infringement</span>
          </p>
          <p class="c1">
            <span class="c0">
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Services
              infringes upon any copyright you own or control, please
              immediately refer to the &quot;COPYRIGHT INFRINGEMENTS&quot;
              section below.
            </span>
          </p>
          <h3 class="c7" id="h.o2ok2bc6mtjm">
            <span class="c4 c17"></span>
          </h3>
          <h2 class="c13" id="h.ot4op6rgwnvp">
            <span class="c3">3. USER REPRESENTATIONS</span>
          </h2>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c4 c12">
              By using the Services, you represent and warrant that: (1) all
              registration information you submit will be true, accurate,
              current, and complete; (2) you will maintain the accuracy of such
              information and promptly update such registration information as
              necessary; (3) you have the legal capacity and you agree to comply
              with these Legal Terms; (4) you are{" "}
            </span>
            <span class="c4">
              at least 18 years old, or the age of majority in your province,
              territory or country, and who can form legally binding contracts
              with us to use the Services, OR, you have the consent and support
              of a parent, legal guardian or other qualified adult
            </span>
            <span class="c0">
              ; (5) you are not a minor in the jurisdiction in which you reside,
              or if a minor, you have received parental permission to use the
              Services; (6) you will not access the Services through automated
              or non-human means, whether through a bot, script or otherwise;
              (7) you will not use the Services for any illegal or unauthorized
              purpose; and (8) your use of the Services will not violate any
              applicable law or regulation.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Services (or any portion thereof).
            </span>
          </p>
          <h2 class="c5" id="h.eqapukax7gh4">
            <span class="c3">4. USER REGISTRATION</span>
          </h2>
          <p class="c1">
            <span class="c0">
              You may be required to register to use the Services. You agree to
              keep your password confidential and will be responsible for all
              use of your account and password. We reserve the right to remove,
              reclaim, or change a username you select if we determine, in our
              sole discretion, that such username is inappropriate, obscene, or
              otherwise objectionable.
            </span>
          </p>
          <h2 class="c5" id="h.fcao7co2un9l">
            <span class="c3">5. PURCHASES AND PAYMENT</span>
          </h2>
          <p class="c1">
            <span class="c4 c12">
              We do not directly accept payment. All subscriptions are directly
              handled by the Apple App Store. For more information on their
              Terms of Service,
            </span>
            <span class="c4 c12">
              <a
                class="c11"
                href="https://www.google.com/url?q=https://www.apple.com/legal/internet-services/itunes/&amp;sa=D&amp;source=editors&amp;ust=1708840693486052&amp;usg=AOvVaw1BMl277c1ZhUXMUmouKdzD"
              >
                &nbsp;
              </a>
            </span>
            <span class="c4 c16">
              <a
                class="c11"
                href="https://www.google.com/url?q=https://www.apple.com/legal/internet-services/itunes/&amp;sa=D&amp;source=editors&amp;ust=1708840693486539&amp;usg=AOvVaw26EXjV3A7GuujhfZCMP8I-"
              >
                please see here.
              </a>
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              In the course of your use of the Services, third party payment
              service providers may receive and implement updated credit card
              information from your credit card issuer in order to prevent your
              payment or subscription from being interrupted by an outdated or
              invalid card. This disbursement of the updated credit card
              information is provided to third party payment service providers
              at the sole election of your credit card issuer. Your credit card
              issuer may give you the right to opt-out of the update service.
              Should you desire to do so, please contact your credit card
              issuer.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              Prices include local taxes. You agree not to hold us responsible
              for banking charges incurred due to payments on your account. You
              agree that you are not permitted to resell any Services for
              commercial purposes.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases made via the Services. You
              further agree to promptly update account and payment information,
              including email address, payment method, and payment card
              expiration date, so that we can complete your transactions and
              contact you as needed. Sales tax will be added to the price of
              purchases as deemed required by us. We may change prices at any
              time. All payments shall be in Your Chosen Currency.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order. We reserve the right to correct any errors or
              mistakes in pricing, even if we have already requested or received
              payment.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We reserve the right to refuse any order placed through the
              Services. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household, or per order.
              These restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </span>
          </p>
          <h2 class="c5" id="h.t92d77gnx490">
            <span class="c3">6. SUBSCRIPTIONS</span>
          </h2>
          <p class="c1">
            <span class="c10 c4 highlight2">Billing and Renewal</span>
          </p>
          <p class="c1">
            <span class="c0">
              Your subscription will continue and automatically renew unless
              canceled. You consent to our charging your payment method on a
              recurring basis without requiring your prior approval for each
              recurring charge, until such time as you cancel the applicable
              order. The length of your billing cycle will depend on the type of
              subscription plan you choose when you subscribed to the Services.
            </span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4"></span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Free Trial</span>
          </p>
          <p class="c1">
            <span class="c0">
              We offer a 30-day free trial to new users who register with the
              Services. The account will be charged according to the user&#39;s
              chosen subscription at the end of the free trial.
            </span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4"></span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Cancellation</span>
          </p>
          <p class="c1">
            <span class="c0">
              You can cancel your subscription at any time by logging into your
              account. Your cancellation will take effect at the end of the
              current paid term. If you have any questions or are unsatisfied
              with our Services, please email us at hello@trainfitness.ai.
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Fee Changes</span>
          </p>
          <p class="c1">
            <span class="c0">
              We may, from time to time, make changes to the subscription fee
              and will communicate any price changes to you in accordance with
              applicable law.
            </span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Refunds</span>
          </p>
          <p class="c1">
            <span class="c0">
              All refunds are handled by the Apple App Store. Train Fitness Inc.
              does not have authority nor capacity to issue refunds. It is the
              sole responsibility of the Registrant or Member to read and
              understand what services are being offered prior to joining.
            </span>
          </p>
          <h2 class="c5" id="h.3jpdddzcixjy">
            <span class="c3">7. SOFTWARE</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We may include software for use in connection with our Services.
              If such software is accompanied by an end user license agreement
              (&quot;EULA&quot;), the terms of the EULA will govern your use of
              the software. If such software is not accompanied by a EULA, then
              we grant to you a non-exclusive, revocable, personal, and
              non-transferable license to use such software solely in connection
              with our services and in accordance with these Legal Terms. Any
              software and any related documentation is provided &quot;AS
              IS&quot; without warranty of any kind, either express or implied,
              including, without limitation, the implied warranties of
              merchantability, fitness for a particular purpose, or
              non-infringement. You accept any and all risk arising out of use
              or performance of any software. You may not reproduce or
              redistribute any software except in accordance with the EULA or
              these Legal Terms.
            </span>
          </p>
          <h2 class="c5" id="h.d5qcqjal8pxz">
            <span class="c3">8. PROHIBITED ACTIVITIES</span>
          </h2>
          <p class="c1">
            <span class="c0">
              You may not access or use the Services for any purpose other than
              that for which we make the Services available. The Services may
              not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </span>
          </p>
          <p class="c1">
            <span class="c0">As a user of the Services, you agree not to:</span>
          </p>
          <ul class="c15 lst-kix_amm7zv3k9tv3-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                Systematically retrieve data or other content from the Services
                to create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Circumvent, disable, or otherwise interfere with
                security-related features of the Services, including features
                that prevent or restrict the use or copying of any Content or
                enforce limitations on the use of the Services and/or the
                Content contained therein.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Disparage, tarnish, or otherwise harm, in our opinion, us and/or
                the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Use any information obtained from the Services in order to
                harass, abuse, or harm another person.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Make improper use of our support services or submit false
                reports of abuse or misconduct.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Use the Services in a manner inconsistent with any applicable
                laws or regulations.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Engage in unauthorized framing of or linking to the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party&rsquo;s
                uninterrupted use and enjoyment of the Services or modifies,
                impairs, disrupts, alters, or interferes with the use, features,
                functions, operation, or maintenance of the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Delete the copyright or other proprietary rights notice from any
                Content.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Attempt to impersonate another user or person or use the
                username of another user.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats (&quot;gifs&quot;), 1&times;1
                pixels, web bugs, cookies, or other similar devices (sometimes
                referred to as &quot;spyware&quot; or &quot;passive collection
                mechanisms&quot; or &quot;pcms&quot;).
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Interfere with, disrupt, or create an undue burden on the
                Services or the networks or services connected to the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Services to you.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Attempt to bypass any measures of the Services designed to
                prevent or restrict access to the Services, or any portion of
                the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Copy or adapt the Services&#39; software, including but not
                limited to Flash, PHP, HTML, JavaScript, or other code.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Except as permitted by applicable law, decipher, decompile,
                disassemble, or reverse engineer any of the software comprising
                or in any way making up a part of the Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Services, or use or launch any unauthorized script or other
                software.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Use a buying agent or purchasing agent to make purchases on the
                Services.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Make any unauthorized use of the Services, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Use the Services as part of any effort to compete with us or
                otherwise use the Services and/or the Content for any
                revenue-generating endeavor or commercial enterprise.
              </span>
            </li>
          </ul>
          <h2 class="c5" id="h.3a3nmre6dtlu">
            <span class="c3">9. USER GENERATED CONTRIBUTIONS</span>
          </h2>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c0">
              The Services may invite you to chat, contribute to, or participate
              in blogs, message boards, online forums, and other functionality,
              and may provide you with the opportunity to create, submit, post,
              display, transmit, perform, publish, distribute, or broadcast
              content and materials to us or on the Services, including but not
              limited to text, writings, video, audio, photographs, graphics,
              comments, suggestions, or personal information or other material
              (collectively, &quot;Contributions&quot;). Contributions may be
              viewable by other users of the Services and through third-party
              websites. As such, any Contributions you transmit may be treated
              as non-confidential and non-proprietary. When you create or make
              available any Contributions, you thereby represent and warrant
              that:
            </span>
          </p>
          <ul class="c15 lst-kix_qkxg2woqaxd0-0 start">
            <li class="c2 li-bullet-0">
              <span class="c0">
                The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                You are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Services, and other users of the Services to
                use your Contributions in any manner contemplated by the
                Services and these Legal Terms.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                You have the written consent, release, and/or permission of each
                and every identifiable individual person in your Contributions
                to use the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Services and
                these Legal Terms.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions are not false, inaccurate, or misleading.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not ridicule, mock, disparage, intimidate,
                or abuse anyone.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not violate any applicable law,
                regulation, or rule.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not violate the privacy or publicity
                rights of any third party.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not violate any applicable law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference, or physical handicap.
              </span>
            </li>
            <li class="c2 li-bullet-0">
              <span class="c0">
                Your Contributions do not otherwise violate, or link to material
                that violates, any provision of these Legal Terms, or any
                applicable law or regulation.
              </span>
            </li>
          </ul>
          <p class="c1">
            <span class="c0">
              Any use of the Services in violation of the foregoing violates
              these Legal Terms and may result in, among other things,
              termination or suspension of your rights to use the Services.
            </span>
          </p>
          <h2 class="c5" id="h.ibzcwvqez3t7">
            <span class="c3">10. CONTRIBUTION LICENSE</span>
          </h2>
          <p class="c1">
            <span class="c0">
              By posting your Contributions to any part of the Services or
              making Contributions accessible to the Services by linking your
              account from the Services to any of your social networking
              accounts, you automatically grant, and you represent and warrant
              that you have the right to grant, to us an unrestricted,
              unlimited, irrevocable, perpetual, non-exclusive, transferable,
              royalty-free, fully-paid, worldwide right, and license to host,
              use, copy, reproduce, disclose, sell, resell, publish, broadcast,
              retitle, archive, store, cache, publicly perform, publicly
              display, reformat, translate, transmit, excerpt (in whole or in
              part), and distribute such Contributions (including, without
              limitation, your image and voice) for any purpose, commercial,
              advertising, or otherwise, and to prepare derivative works of, or
              incorporate into other works, such Contributions, and grant and
              authorize sublicenses of the foregoing. The use and distribution
              may occur in any media formats and through any media channels.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes our use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Services. You are solely responsible for your Contributions
              to the Services and you expressly agree to exonerate us from any
              and all responsibility and to refrain from any legal action
              against us regarding your Contributions.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Services; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice. We
              have no obligation to monitor your Contributions.
            </span>
          </p>
          <h2 class="c5" id="h.zfsozrxkpssm">
            <span class="c3">11. MOBILE APPLICATION LICENSE</span>
          </h2>
          <p class="c1">
            <span class="c4 c10 highlight2">Use License</span>
          </p>
          <p class="c1">
            <span class="c0">
              If you access the Services via the App, then we grant you a
              revocable, non-exclusive, non-transferable, limited right to
              install and use the App on wireless electronic devices owned or
              controlled by you, and to access and use the App on such devices
              strictly in accordance with the terms and conditions of this
              mobile application license contained in these Legal Terms. You
              shall not: (1) except as permitted by applicable law, decompile,
              reverse engineer, disassemble, attempt to derive the source code
              of, or decrypt the App; (2) make any modification, adaptation,
              improvement, enhancement, translation, or derivative work from the
              App; (3) violate any applicable laws, rules, or regulations in
              connection with your access or use of the App; (4) remove, alter,
              or obscure any proprietary notice (including any notice of
              copyright or trademark) posted by us or the licensors of the App;
              (5) use the App for any revenue-generating endeavor, commercial
              enterprise, or other purpose for which it is not designed or
              intended; (6) make the App available over a network or other
              environment permitting access or use by multiple devices or users
              at the same time; (7) use the App for creating a product, service,
              or software that is, directly or indirectly, competitive with or
              in any way a substitute for the App; (8) use the App to send
              automated queries to any website or to send any unsolicited
              commercial email; or (9) use any proprietary information or any of
              our interfaces or our other intellectual property in the design,
              development, manufacture, licensing, or distribution of any
              applications, accessories, or devices for use with the App.
            </span>
          </p>
          <p class="c1">
            <span class="c10 c4 highlight2">Apple and Android Devices</span>
          </p>
          <p class="c1">
            <span class="c0">
              The following terms apply when you use the App obtained from
              either the Apple Store or Google Play (each an &quot;App
              Distributor&quot;) to access the Services: (1) the license granted
              to you for our App is limited to a non-transferable license to use
              the application on a device that utilizes the Apple iOS or Android
              operating systems, as applicable, and in accordance with the usage
              rules set forth in the applicable App Distributor&rsquo;s terms of
              service; (2) we are responsible for providing any maintenance and
              support services with respect to the App as specified in the terms
              and conditions of this mobile application license contained in
              these Legal Terms or as otherwise required under applicable law,
              and you acknowledge that each App Distributor has no obligation
              whatsoever to furnish any maintenance and support services with
              respect to the App; (3) in the event of any failure of the App to
              conform to any applicable warranty, you may notify the applicable
              App Distributor, and the App Distributor, in accordance with its
              terms and policies, may refund the purchase price, if any, paid
              for the App, and to the maximum extent permitted by applicable
              law, the App Distributor will have no other warranty obligation
              whatsoever with respect to the App; (4) you represent and warrant
              that (i) you are not located in a country that is subject to a US
              government embargo, or that has been designated by the US
              government as a &quot;terrorist supporting&quot; country and (ii)
              you are not listed on any US government list of prohibited or
              restricted parties; (5) you must comply with applicable
              third-party terms of agreement when using the App, e.g., if you
              have a VoIP application, then you must not be in violation of
              their wireless data service agreement when using the App; and (6)
              you acknowledge and agree that the App Distributors are
              third-party beneficiaries of the terms and conditions in this
              mobile application license contained in these Legal Terms, and
              that each App Distributor will have the right (and will be deemed
              to have accepted the right) to enforce the terms and conditions in
              this mobile application license contained in these Legal Terms
              against you as a third-party beneficiary thereof.
            </span>
          </p>
          <h2 class="c5" id="h.pfcpvc6i5n1a">
            <span class="c3">12. SOCIAL MEDIA</span>
          </h2>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c0">
              As part of the functionality of the Services, you may link your
              account with online accounts you have with third-party service
              providers (each such account, a &quot;Third-Party Account&quot;)
              by either: (1) providing your Third-Party Account login
              information through the Services; or (2) allowing us to access
              your Third-Party Account, as is permitted under the applicable
              terms and conditions that govern your use of each Third-Party
              Account. You represent and warrant that you are entitled to
              disclose your Third-Party Account login information to us and/or
              grant us access to your Third-Party Account, without breach by you
              of any of the terms and conditions that govern your use of the
              applicable Third-Party Account, and without obligating us to pay
              any fees or making us subject to any usage limitations imposed by
              the third-party service provider of the Third-Party Account. By
              granting us access to any Third-Party Accounts, you understand
              that (1) we may access, make available, and store (if applicable)
              any content that you have provided to and stored in your
              Third-Party Account (the &quot;Social Network Content&quot;) so
              that it is available on and through the Services via your account,
              including without limitation any friend lists and (2) we may
              submit to and receive from your Third-Party Account additional
              information to the extent you are notified when you link your
              account with the Third-Party Account. Depending on the Third-Party
              Accounts you choose and subject to the privacy settings that you
              have set in such Third-Party Accounts, personally identifiable
              information that you post to your Third-Party Accounts may be
              available on and through your account on the Services. Please note
              that if a Third-Party Account or associated service becomes
              unavailable or our access to such Third-Party Account is
              terminated by the third-party service provider, then Social
              Network Content may no longer be available on and through the
              Services. You will have the ability to disable the connection
              between your account on the Services and your Third-Party Accounts
              at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE
              THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
              ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
              THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any
              Social Network Content for any purpose, including but not limited
              to, for accuracy, legality, or non-infringement, and we are not
              responsible for any Social Network Content. You acknowledge and
              agree that we may access your email address book associated with a
              Third-Party Account and your contacts list stored on your mobile
              device or tablet computer solely for purposes of identifying and
              informing you of those contacts who have also registered to use
              the Services. You can deactivate the connection between the
              Services and your Third-Party Account by contacting us using the
              contact information below or through your account settings (if
              applicable). We will attempt to delete any information stored on
              our servers that was obtained through such Third-Party Account,
              except the username and profile picture that become associated
              with your account.
            </span>
          </p>
          <h2 class="c5" id="h.9da3d1dmhrrk">
            <span class="c3">13. SERVICES MANAGEMENT</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We reserve the right, but not the obligation, to: (1) monitor the
              Services for violations of these Legal Terms; (2) take appropriate
              legal action against anyone who, in our sole discretion, violates
              the law or these Legal Terms, including without limitation,
              reporting such user to law enforcement authorities; (3) in our
              sole discretion and without limitation, refuse, restrict access
              to, limit the availability of, or disable (to the extent
              technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation,
              notice, or liability, to remove from the Services or otherwise
              disable all files and content that are excessive in size or are in
              any way burdensome to our systems; and (5) otherwise manage the
              Services in a manner designed to protect our rights and property
              and to facilitate the proper functioning of the Services.
            </span>
          </p>
          <h2 class="c5" id="h.yjslnen9eirv">
            <span class="c3">14. PRIVACY POLICY</span>
          </h2>
          <p class="c1">
            <span class="c4 c12">
              We care about data privacy and security. Please review our Privacy
              Policy:
            </span>
            <span class="c4 c12">
              <a
                class="c11"
                href="https://www.google.com/url?q=https://trainfitness.ai/privacy&amp;sa=D&amp;source=editors&amp;ust=1708840693497008&amp;usg=AOvVaw2W7fnPgXCcsprLAepHBj9K"
              >
                &nbsp;
              </a>
            </span>
            <span class="c16 c4">
              <a
                class="c11"
                href="https://www.google.com/url?q=https://trainfitness.ai/privacy&amp;sa=D&amp;source=editors&amp;ust=1708840693497413&amp;usg=AOvVaw1nOHvDDRs3ujg_I3F0AjY9"
              >
                https://trainfitness.ai/privacy
              </a>
            </span>
            <span class="c0">
              . By using the Services, you agree to be bound by our Privacy
              Policy, which is incorporated into these Legal Terms. Please be
              advised the Services are hosted in the United States and Canada.
              If you access the Services from any other region of the world with
              laws or other requirements governing personal data collection,
              use, or disclosure that differ from applicable laws in the United
              States and Canada, then through your continued use of the
              Services, you are transferring your data to the United States and
              Canada, and you expressly consent to have your data transferred to
              and processed in the United States and Canada. Further, we do not
              knowingly accept, request, or solicit information from children or
              knowingly market to children. Therefore, in accordance with the
              U.S. Children&rsquo;s Online Privacy Protection Act, if we receive
              actual knowledge that anyone under the age of 13 has provided
              personal information to us without the requisite and verifiable
              parental consent, we will delete that information from the
              Services as quickly as is reasonably practical.
            </span>
          </p>
          <h2 class="c5" id="h.2i505qpoz3y2">
            <span class="c3">15. COPYRIGHT INFRINGEMENTS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Services
              infringes upon any copyright you own or control, please
              immediately notify us using the contact information provided below
              (a &quot;Notification&quot;). A copy of your Notification will be
              sent to the person who posted or stored the material addressed in
              the Notification. Please be advised that pursuant to applicable
              law you may be held liable for damages if you make material
              misrepresentations in a Notification. Thus, if you are not sure
              that material located on or linked to by the Services infringes
              your copyright, you should consider first contacting an attorney.
            </span>
          </p>
          <h2 class="c5" id="h.64vcn9c8q9o9">
            <span class="c3">16. TERM AND TERMINATION</span>
          </h2>
          <p class="c1">
            <span class="c0">
              These Legal Terms shall remain in full force and effect while you
              use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
              BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
              THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
              TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
              ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
              TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c0">
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </span>
          </p>
          <h2 class="c5" id="h.hb70i2tzm896">
            <span class="c3">17. MODIFICATIONS AND INTERRUPTIONS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We reserve the right to change, modify, or remove the contents of
              the Services at any time or for any reason at our sole discretion
              without notice. However, we have no obligation to update any
              information on our Services. We will not be liable to you or any
              third party for any modification, price change, suspension, or
              discontinuance of the Services.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We cannot guarantee the Services will be available at all times.
              We may experience hardware, software, or other problems or need to
              perform maintenance related to the Services, resulting in
              interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the
              Services at any time or for any reason without notice to you. You
              agree that we have no liability whatsoever for any loss, damage,
              or inconvenience caused by your inability to access or use the
              Services during any downtime or discontinuance of the Services.
              Nothing in these Legal Terms will be construed to obligate us to
              maintain and support the Services or to supply any corrections,
              updates, or releases in connection therewith.
            </span>
          </p>
          <h2 class="c5" id="h.dmv0xwc77m1l">
            <span class="c3">18. GOVERNING LAW</span>
          </h2>
          <p class="c1">
            <span class="c0">
              These Legal Terms shall be governed by and defined following the
              laws of Canada. Train Fitness Inc. and yourself irrevocably
              consent that the courts of Canada shall have exclusive
              jurisdiction to resolve any dispute which may arise in connection
              with these Legal Terms.
            </span>
          </p>
          <h2 class="c5" id="h.icn2sza2i980">
            <span class="c3">19. DISPUTE RESOLUTION</span>
          </h2>
          <p class="c1">
            <span class="c0">
              You agree to irrevocably submit all disputes related to these
              Legal Terms or the legal relationship established by these Legal
              Terms to the jurisdiction of the Canada courts. Train Fitness Inc.
              shall also maintain the right to bring proceedings as to the
              substance of the matter in the courts of the country where you
              reside or, if these Legal Terms are entered into in the course of
              your trade or profession, the state of your principal place of
              business.
            </span>
          </p>
          <h2 class="c5" id="h.vux7jfr4vhln">
            <span class="c3">20. CORRECTIONS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              There may be information on the Services that contains
              typographical errors, inaccuracies, or omissions, including
              descriptions, pricing, availability, and various other
              information. We reserve the right to correct any errors,
              inaccuracies, or omissions and to change or update the information
              on the Services at any time, without prior notice.
            </span>
          </p>
          <h2 class="c5" id="h.7iqx5ehv220y">
            <span class="c3">21. DISCLAIMER</span>
          </h2>
          <p class="c1">
            <span class="c0">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
              THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
              EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
              THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT
              THE ACCURACY OR COMPLETENESS OF THE SERVICES&#39; CONTENT OR THE
              CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE
              SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
              RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
              UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
              ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
              THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
              FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
              LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
              THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
              MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
              MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE,
              GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
              ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
              HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED
              IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO
              OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
              BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
              AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
              IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
              CAUTION WHERE APPROPRIATE.
            </span>
          </p>
          <h2 class="c5" id="h.xkfll15pm2nh">
            <span class="c3">22. LIMITATIONS OF LIABILITY</span>
          </h2>
          <p class="c1">
            <span class="c0">
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF
              THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
              PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $250.00 USD.
              CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
              OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
              HAVE ADDITIONAL RIGHTS.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              TRAIN FITNESS&rsquo; SERVICES MAY BE USED BY YOU TO PLAN AND
              CONDUCT A WORKOUT, BUT YOU AGREE THAT TRAIN FITNESS HAS NO
              RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY EXERCISE,
              WORKING OUT, OR WORKOUT PLAN OTHER THAN AS EXPRESSLY SET FORTH IN
              THESE TERMS.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              YOU ACKNOWLEDGE THAT SERVICES PROVIDED BY TRAIN FITNESS HAVE NOT
              BEEN DEVELOPED TO MEET YOUR INDIVIDUAL NEEDS OR REQUIREMENTS, AND
              THUS IT IS YOUR RESPONSIBILITY TO ENSURE THE FACILITIES AND
              FUNCTIONS OF THE TRAIN FITNESS APPLICATION MEET YOUR REQUIREMENTS.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              YOU ACKNOWLEDGE THAT WEIGHT TRAINING AND ANAEROBIC ACTIVITY CARY
              CERTAIN INHERENT RISKS, NOT LIMITED TO PERSONAL INJURY, PROPERTY
              DAMAGE, OR DEATH. YOU ACKNOWLEDGE THAT YOU UNDERTAKE SUCH
              ACTIVITIES ENTIRELY AT YOUR OWN RISK.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              YOU AGREE AND ACKNOWLEDGE THAT TRAIN FITNESS, ITS OFFICERS,
              OWNERS, EMPLOYEES, AND AGENTS HAVE NO LIABILITY TO YOU OR A
              DEPENDANT FOR ANY LOSS OF EMPLOYMENT, WATGES, SALARY, PROFIT, LOSS
              OF BUSINESS, BUSINESS INTERRUPTION, OR OTHER LOSEE OF INCOME.
            </span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <h2 class="c5" id="h.c5x70tlaeo9g">
            <span class="c3">23. NO MEDICAL ADVICE</span>
          </h2>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1">
            <span class="c0">
              Information provided by the Service is informational only. Train
              Fitness Inc. provides the Services for you to track, record,
              manage, share, and plan fitness activities. The Services do not
              constitute as, and should not be interpreted as medical advice,
              opinion, or information. Train Fitness Inc. is not a licensed
              medical service, and thus do not offer medical advice or services.
              You should always consult a qualified and licensed medical
              professional prior to beginning or modifying any exercise program.
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              You agree you will not implement any workout or otherwise fitness
              advice, information, or recommendations that may or may not be
              provided by the Service without first consulting and seeking
              advice from an appropriate trainer in your area and/or ensuring
              such activities are safe and appropriate for you. Train Fitness
              Inc. cannot possibly ascertain what activities are safe for you
              and your body. You agree you will not bring any complaints or
              claims against Train Fitness Inc. arising from any issues with
              exercise recommendations.
            </span>
          </p>
          <h2 class="c5" id="h.gyybcmhsbhlk">
            <span class="c3">24. INDEMNIFICATION</span>
          </h2>
          <p class="c1">
            <span class="c0">
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys&rsquo; fees and expenses, made by any third party due to
              or arising out of: (1) your Contributions; (2) use of the
              Services; (3) breach of these Legal Terms; (4) any breach of your
              representations and warranties set forth in these Legal Terms; (5)
              your violation of the rights of a third party, including but not
              limited to intellectual property rights; or (6) any overt harmful
              act toward any other user of the Services with whom you connected
              via the Services. Notwithstanding the foregoing, we reserve the
              right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify us,
              and you agree to cooperate, at your expense, with our defense of
              such claims. We will use reasonable efforts to notify you of any
              such claim, action, or proceeding which is subject to this
              indemnification upon becoming aware of it.
            </span>
          </p>
          <h2 class="c5" id="h.er87o27y0me9">
            <span class="c3">25. USER DATA</span>
          </h2>
          <p class="c1">
            <span class="c0">
              We will maintain certain data that you transmit to the Services
              for the purpose of managing the performance of the Services, as
              well as data relating to your use of the Services. Although we
              perform regular routine backups of data, you are solely
              responsible for all data that you transmit or that relates to any
              activity you have undertaken using the Services. You agree that we
              shall have no liability to you for any loss or corruption of any
              such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.
            </span>
          </p>
          <h2 class="c5" id="h.tquymftbqqhc">
            <span class="c3">
              26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
          </h2>
          <p class="c1">
            <span class="c0">
              Visiting the Services, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Services, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE SERVICES. You hereby waive any rights or requirements under
              any statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </span>
          </p>
          <h2 class="c5" id="h.uq7pui7l86fg">
            <span class="c3">27. CALIFORNIA USERS AND RESIDENTS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              If any complaint with us is not satisfactorily resolved, you can
              contact the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs in
              writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916)
              445-1254.
            </span>
          </p>
          <h2 class="c5" id="h.st1opygcgpj4">
            <span class="c3">28. &nbsp; MISCELLANEOUS</span>
          </h2>
          <p class="c1">
            <span class="c0">
              These Legal Terms and any policies or operating rules posted by us
              on the Services or in respect to the Services constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Legal Terms
              shall not operate as a waiver of such right or provision. These
              Legal Terms operate to the fullest extent permissible by law. We
              may assign any or all of our rights and obligations to others at
              any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our
              reasonable control. If any provision or part of a provision of
              these Legal Terms is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Legal Terms and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Legal Terms or use of the
              Services. You agree that these Legal Terms will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Legal Terms and the lack of signing by the parties hereto to
              execute these Legal Terms.
            </span>
          </p>
          <h2 class="c5" id="h.o9xizale9lo3">
            <span class="c3">29. CONTACT US</span>
          </h2>
          <p class="c1">
            <span class="c0">
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at:
            </span>
          </p>
          <p class="c1">
            <span class="c0">Train Fitness Inc.</span>
          </p>
          <p class="c1">
            <span class="c0">602-130 Spadina Avenue</span>
          </p>
          <p class="c1">
            <span class="c0">Toronto, Ontario M5V 0H4</span>
          </p>
          <p class="c1">
            <span class="c0">Canada</span>
          </p>
          <p class="c1">
            <span class="c0">hello@trainfitness.ai</span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c1 c9">
            <span class="c10 c4 highlight2"></span>
          </p>
          <p class="c9 c14">
            <span class="c10 c18"></span>
          </p>
        </div>
      </div>
    </Container>
  );
}

export default Terms;

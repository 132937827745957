import React from "react";
import "./Header.css";
import WorkoutDetailBanner from "../../../Images/workout-detail-banner.png";

const WorkoutDetailHeader = () => {
  return (
    <div className="workout-detail-header-container">
      <div className="workout-detail-header-text-container">
        <p className="workout-detail-header-desktop-text">
          Step up your game with automated workout tracking.
        </p>
        <p className="workout-detail-header-mobile-text">
          Step up your game with automated workout tracking.
        </p>
      </div>
      <div className="workout-detail-header-button-container">
        <a
          className="workout-detail-header-join-button"
          href="https://apps.apple.com/ca/app/train-fitness-workout-tracker/id1548577496"
        >
          <div className="workout-detail-header-join-button-text">
            Join Train For Free
          </div>
        </a>
      </div>
      <img
        src={WorkoutDetailBanner}
        className="workout-detail-header-bottom-right-image"
      />
    </div>
  );
};

export default WorkoutDetailHeader;
